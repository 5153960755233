import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import nonUserService from "../../services/nonUserService";

function useNonUserAccountAppSites({ parentExternalId }) {
    return useQuery(
        [queryKeys.nonUserAccountSites],
        () => nonUserService.fetchAccountAppSites({
            parentExternalId
        }),
        {
            placeholderData: { data: [] }
        }
    );
}

export default useNonUserAccountAppSites; 