import {useQuery} from "react-query";
import queryKeys from "../../../constants/queryKeys";
import incidentService from "../../services/incidentService";

const useIncidentCategories = () =>
    useQuery(
        [queryKeys.incidentCategories],
        incidentService.fetchIncidentCategories,
        {
            staleTime: Infinity
        }
    )
export default useIncidentCategories;