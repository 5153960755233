import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import templateService from "../../services/templateService";

const useAllModuleEnabledTemplates = ({ moduleArea, accountId, siteExternalIds, includeDisabled = false }) =>
    useQuery(
        [queryKeys.templates, moduleArea, accountId, siteExternalIds?.join(",")],
    () =>
      templateService.fetchAllTemplatesForModule({
        moduleArea,
        accountId,
        siteExternalIds,
        includeDisabled         
      }), 
        {
            enabled: !!includeDisabled && !!siteExternalIds
        }
  );

  

export default useAllModuleEnabledTemplates;
