import React, { useState } from 'react';
import { Container, Box, Typography, makeStyles, Divider, Snackbar } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import { useSelector } from "react-redux";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Layout from '../layout/Layout';
import NonUserFormSection from './NonUserFormSection';
import ProgressPills from '../../../components/common/ProgressPills';
import PageHeader from '../layout/PageHeader';
const useStyles = makeStyles((theme) => ({
    pageTitle: {
        color: '#1c1b1f',
        fontWeight: 600,
        fontSize: '28px',
        lineHeight: 1.29,
        textAlign: 'center',
        fontFamily: 'Inter, sans-serif'
    },
    sectionDescription: {
        color: 'rgba(28, 27, 31, 0.6)',
        fontFamily: 'Inter, sans-serif',
        marginBottom: theme.spacing(2)
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
    },
    errorContainer: {
        marginTop: theme.spacing(2),
    },
    sectionsContainer: {
        display: "flex",
        flexDirection: "column",
        width: '100%',
    }
}));

function IncidentFormStep({ onSubmit, onBack, formData, setFormData, currentStep, totalSteps }) {
    const classes = useStyles();
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const questionResponses = useSelector((state) => state.formCompleter.questionResponses);

    const validateMandatoryFields = () => {
        const mandatoryQuestions = [];
        
        formData.templateVersion?.sections.forEach(section => {
            section.questions.forEach(question => {
                if (question.isMandatory) {
                    const response = questionResponses[question.id];
                    const isEmpty = !response || 
                        (response.textValue === undefined && 
                         response.numberValue === undefined && 
                         response.dateValue === undefined && 
                         response.optionId === undefined && 
                         (!response.optionIds || response.optionIds.length === 0));

                    if (isEmpty) {
                        mandatoryQuestions.push(question.text);
                    }
                }
            });
        });

        return mandatoryQuestions;
    };

    const handleNext = () => {
        const missingFields = validateMandatoryFields();
        
        if (missingFields.length > 0) {
            setErrorMessage(`Please complete the following required fields: ${missingFields.join(', ')}`);
            setShowError(true);
            return;
        }

        if (typeof onSubmit === 'function') {
            onSubmit({
                ...formData,
                formResponses: questionResponses
            });
        }
    };

    const handleCloseError = () => {
        setShowError(false);
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Layout
                onNext={handleNext}
                onBack={onBack}
                showBackButton={true}
                currentStep={currentStep}
                totalSteps={totalSteps}
                nextButtonText="Submit"
            >
                <Container maxWidth="md">
                <PageHeader />
                    <Box>
                        <Divider />
                    </Box>

                    {(currentStep !== undefined && totalSteps !== undefined) && (
                        <div className={classes.progressContainer}>
                            <ProgressPills 
                                currentStep={currentStep} 
                                totalSteps={totalSteps} 
                            />
                        </div>
                    )}

                    <div className={classes.sectionsContainer}>
                        {formData.templateVersion?.sections.map((section) => (
                            <div key={section.id}>
                                <Typography className={classes.sectionTitle}>
                                    {section.title}
                                </Typography>
                                <NonUserFormSection 
                                    section={section}
                                    value={questionResponses}
                                />
                            </div>
                        ))}
                    </div>
                </Container>
            </Layout>

            <Snackbar 
                open={showError} 
                autoHideDuration={6000} 
                onClose={handleCloseError}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseError} severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>
        </MuiPickersUtilsProvider>
    );
}

export default IncidentFormStep; 