import axios from "../../utils/axios";
import { buildQueryStringList } from "../../utils/stringUtils";


const accountEndpointUrl = `${process.env.REACT_APP_MYWORKNEST_API_URL}/Account`;
const apiUrl = process.env.REACT_APP_HS_API_URL;

const fetchNonUser = (nonUserRef) => {
    return axios.get(
        `${apiUrl}/NonUser/Get`, {
        params: {
            nonUserRef: nonUserRef
        }
    }
    );
}

const addNonUser = (nonUser) => {
    return axios.post(`${apiUrl}/NonUser/Add`, nonUser);
}

const fetchIncidentCategories = () =>
    axios.get(`${apiUrl}/nonuser/incidentCategories`);

const addIncident = (incident) => {
    return axios.post(`${apiUrl}/NonUser/NewIncident`, incident);
 }

const nonUserService = {
    fetchNonUser,
    addNonUser,
    addIncident,
    fetchIncidentCategories,
    fetchTemplates: (externalId) =>
        axios.get(`${apiUrl}/nonuser/templates`, {
            params: { externalId }
        }),

    fetchIncidentFormVersion: (templateVersionId, externalId) =>
        axios.get(`${apiUrl}/NonUser/TemplateVersionAndInvestigation/${templateVersionId}`, {
            params: { 
                externalId 
            }
        }),

    fetchAllTemplatesForModule: ({ moduleArea, accountId, siteExternalIds, includeDisabled }) => {
        const sitesList = buildQueryStringList("siteExternalIds", siteExternalIds);
        return axios.get(
            `${apiUrl}/NonUser/ApplicationArea/All/${moduleArea}${sitesList}&accountId=${accountId}&includeDisabled=${includeDisabled}`
      );
    },

    fetchAccountAppSites: ({ parentExternalId }) => {
        return axios.get(`${accountEndpointUrl}/AccountTree/SafetyNest/${parentExternalId}`);
    },
    
};

export default nonUserService;