import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import nonUserService from "../../services/nonUserService";

function useNonUserAllEnabledModuleTemplates({ moduleArea, accountId, siteExternalIds, includeDisabled = false }) {
    return useQuery(
        [queryKeys.nonUserTemplates, moduleArea, accountId, siteExternalIds?.join(",")],
        () => nonUserService.fetchAllTemplatesForModule({
            moduleArea,
            accountId,
            siteExternalIds,
            includeDisabled         
        }), 
        {
            enabled: !!siteExternalIds
        }
    );
}

export default useNonUserAllEnabledModuleTemplates; 