import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Paper } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    backgroundColor: "#eef0ff",
    border: "1px solid #9caadb",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  infoIcon: {
    color: "#374570",
    alignSelf: "center",
  },
  errorIcon: {
    color: theme.palette.error.main,
    alignSelf: "center",
  },
  content: {
    flex: 1,
  },
  title: {
    color: "#374570",
    marginBottom: theme.spacing(0.5),
    fontWeight: 700,
  },
  details: {
    display: "flex",
    gap: theme.spacing(1),
    color: "#374570",
    "& span:not(:last-child)::after": {
      content: '"|"',
      marginLeft: theme.spacing(1),
      color: "#374570",
    },
  },
  loading: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    color: "#374570",
  },
}));

const LoggedByExternalUser = ({ nonUser }) => {

    const classes = useStyles();

  if(nonUser)
  return (
    <Paper variant="outlined" className={classes.paper}>
      <InfoOutlinedIcon className={classes.infoIcon} />
      <div className={classes.content}>
        <Typography variant="subtitle2" className={classes.title}>
          Logged by external user
        </Typography>
        <div className={classes.details}>
          <span>{nonUser.name}</span>
          <span>{nonUser.email}</span>
          {nonUser.phone && <span>{nonUser.phone}</span>}
        </div>
      </div>
    </Paper>
  );
};

export default LoggedByExternalUser; 