import React from 'react';
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    header: {
        padding: theme.spacing(2),
        borderBottom: '1px solid #E0E0E0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    logoContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    logo: {
        height: '24px',
        width: '24px',
    },
    title: {
        margin: 0,
        fontSize: '16px',
        fontWeight: 600,
        color: '#14124F',
    }
}));

function Header() {
    const classes = useStyles();
    
    return (
        <header className={classes.header}>
            <div className={classes.logoContainer}>
                <img 
                    src="/logo192.png" 
                    alt="SafetyNest Logo" 
                    className={classes.logo}
                />
                <h1 className={classes.title}>SafetyNest</h1>
            </div>
        </header>
    );
}

export default Header; 