import Home from "./pages/Home";
import UserImpersonation from "./pages/UserImpersonation";
import AccountSelect from "./pages/AccountSelect";
import InformationHub from "./pages/InformationHub";
import ErrorPage from "./pages/ErrorPage";
import ConsultantAccountSelect from "./pages/ConsultantAccountSelect";
import LeiTerms from "./pages/LeiTerms";
import Documents from "./pages/Documents";
import DocumentSelect from "./pages/DocumentSelect";
import Privacy from "./pages/Privacy";
import Processing from "./pages/Processing";
import { documentTypes } from "./constants/documentConstants";
import Administration from "./pages/Administration";
import Tutorials from "./pages/Tutorials";
import { baseRoute as safetynestBaseRoute } from "./safetynest/routes";
import SafetynestIndex from "./safetynest/SafetynestIndex";
import HubCategories from "./pages/KnowedgeHub/HubCategories";
import HubCategory from "./pages/KnowedgeHub/HubCategory";
import HubItemDetails from "./pages/KnowedgeHub/HubItemDetails";
import EditTutorial from "./pages/EditTutorial";
import AddTutorial from "./pages/AddTutorial";
import Login from "./pages/Login";
import Hello from "./pages/Hello";
import EditUser from "./pages/EditUser";
import AddUser from "./pages/AddUser";
import ResourceNest from "./resourcenest/ResourceNest";
import TrustCentreHome from "./pages/TrustCentre/TrustCentreHome";
import PrivacyPolicy from "./pages/TrustCentre/policies/PrivacyPolicy";
import CookiePolicy from "./pages/TrustCentre/policies/CookiePolicy";
import TermsOfUsePolicy from "./pages/TrustCentre/policies/TermsOfUsePolicy";
import AcceptableUsePolicy from "./pages/TrustCentre/policies/AcceptableUsePolicy";
import Acknowledge from "./pages/Acknowledge";
import NonUserIncidentForm from "./safetynest/pages/nonuser/incidents/NonUserIncidentForm";
import {Redirect} from "react-router-dom";
import { Switch, Route } from "react-router-dom";

export const protectedRoutes = [
    {
        label: "Home",
        path: "/",
        exact: true,
        component: Home,
    },
    {
        label: "Knowledge Hub",
        path: "/knowledgehub/:sectionName",
        exact: true,
        component: HubCategories,
    },
    {
        label: "Knowledge Hub Category",
        path: "/knowledgehub/:sectionName/:categoryId",
        exact: true,
        component: HubCategory,
    },
    {
        label: "Knowledge Hub Category",
        path: "/knowledgehub/:sectionName/search/:searchTerm",
        exact: true,
        component: HubCategory,
    },
    {
        label: "Knowledge Hub Category",
        path: "/knowledgehub/:sectionName/:categoryId/:itemType/:itemId/:cachePage",
        exact: true,
        component: HubItemDetails,
    },
    {
        label: "User Impersonation",
        path: "/impersonate",
        component: UserImpersonation,
        hideFromTutorials: true,
    },
    {
        label: "Account Select",
        path: "/account-select",
        component: AccountSelect,
        hideFromTutorials: true,
    },
    {
        label: "Error",
        path: "/error",
        component: ErrorPage,
        hideFromTutorials: true,
    },
    {
        label: "Information Hub",
        path: "/welcome",
        component: InformationHub,
    },
    {
        label: "Legal Expenses Insurance - Terms",
        path: "/lei",
        component: LeiTerms,
    },
    {
        label: "Consultant Account Select",
        path: "/consultant-account-select",
        component: ConsultantAccountSelect,
        hideFromTutorials: true,
    },
    {
        label: "Document Manager",
        path: "/documents",
        component: DocumentSelect,
        exact: true,
    },
    {
        label: "Document Manager",
        path: `/documents/:documentTypeValue(${documentTypes.ACCOUNT.value}|${documentTypes.WORKNEST.value})`,
        component: Documents,
    },
    {
        label: "Administration",
        path: "/administration",
        component: Administration,
    },
    {
        label: "Add User",
        path: "/add-user",
        component: AddUser,
    },
    {
        label: "Edit User",
        path: "/edit-user",
        component: EditUser,
    },
    {
        label: "Tutorial Builder",
        path: "/tutorial-builder",
        exact: true,
        component: Tutorials,
        hideFromTutorials: true,
    },
    {
        label: "Tutorial Builder",
        path: "/tutorial-builder/add",
        component: AddTutorial,
        exact: true,
        hideFromTutorials: true,
    },
    {
        label: "Tutorial Builder",
        path: "/tutorial-builder/edit/:tutorialId",
        exact: true,
        component: EditTutorial,
        hideFromTutorials: true,
    },
    {
        label: "Safetynest",
        path: safetynestBaseRoute,
        component: SafetynestIndex,
        hideFromTutorials: true,
    },    
    {
        label: "Privacy Policy",
        path: "/privacy",
        component: Privacy,
        hideFromTutorials: true,
    },
    {
        label: "Processing Policy",
        path: "/processing",
        component: Processing,
        hideFromTutorials: true,
    },
    {
        label: "ResourceNest",
        path: "/resourcenest/:licence",
        component: ResourceNest,
        hideFromTutorials: true,
    },
    {
        path: "/trustcentre",
        exact: true,
        component: TrustCentreHome,
        hideFromTutorials: true,
    },
    {
        path: "/trustcentre/privacy",
        exact: true,
        component: PrivacyPolicy,
        hideFromTutorials: true,
    },
    {
        path: "/trustcentre/cookies",
        exact: true,
        component: CookiePolicy,
        hideFromTutorials: true,
    },
    {
        path: "/trustcentre/termsofuse",
        exact: true,
        component: TermsOfUsePolicy,
        hideFromTutorials: true,
    },
    {
        path: "/trustcentre/acceptableuse",
        exact: true,
        component: AcceptableUsePolicy,
        hideFromTutorials: true,
    },
    {
      path: "/acknowledge/:reference",
      exact: true,
      component: Acknowledge,
    },
];

export const publicRoutes = [
    {
        path: "/safetynest/report-incident",
        params: {
            externalId: ':externalId'
        },
        component: NonUserIncidentForm,
        exact: true
    },
    {
        path: "/",
        exact: true,
        component: Login,
    },
    {
        path: "/hello",
        exact: true,
        component: Hello,
    },
    {
        path: "/acknowledge/:reference",
        exact: true,
        component: Acknowledge,
    },
    {
        path: "*",
        component: () => <Redirect to="/" />,
    }

];
