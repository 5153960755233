import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  pillContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: theme.spacing(1),
    padding: theme.spacing(2, 0),
    width: '100%',
    maxWidth: '1200px',
    margin: '0 auto',
    [theme.breakpoints.up('sm')]: {
      gap: theme.spacing(2),
    }
  },
  pill: {
    width: 87,
    height: 8,
    borderRadius: 99,
    backgroundColor: '#E6E1E5',
    transition: 'background-color 0.3s ease',
    [theme.breakpoints.up('sm')]: {
      flex: 1,
      maxWidth: 'none',
      width: 'auto',
    }
  },
  activePill: {
    backgroundColor: '#6750A4'
  },
  completedPill: {
    backgroundColor: '#6750A4'
  }
}));

function ProgressPills({ currentStep = 1, totalSteps = 4 }) {
  const classes = useStyles();

  return (
    <div className={classes.pillContainer}>
      {[...Array(totalSteps)].map((_, index) => (
        <div
          key={index}
          className={`${classes.pill} ${
            index + 1 <= currentStep ? classes.activePill : ''
          }`}
          aria-label={`Step ${index + 1} of ${totalSteps}`}
        />
      ))}
    </div>
  );
}

export default ProgressPills; 