import React, { useRef, useState, useEffect } from "react";
import CardBase from "../../components/cards/CardBase";
import useSiteCurrentActions from "../hooks/queries/useSiteCurrentActions";
import { useDispatch, useSelector } from "react-redux";
import ActionsTable from "./ActionsTable";
import { tablePage } from "../constants/tablePageConstants";
import { setActionsTablePage } from "../../redux/actions/tablePageActions";
import { positiveNumberOrNull } from "../../utils/numberUtils";
import moduleAreas, {
    moduleFileLabels,
} from "../constants/moduleAreaConstants";
import { Button, makeStyles, Dialog, DialogTitle, DialogActions, FormControlLabel, Switch } from "@material-ui/core";
import { GetApp, WifiTethering } from "@material-ui/icons";
import { CSVLink } from "react-csv";
import actionService from "../services/actionService";
import { useSnackbar } from "notistack";
import { format, isValid } from "date-fns";
import useContactsForAppAndSite from "../../hooks/useContactsForAppAndSite";
import { useAuth } from "../../contexts/authContext";
import sortOrder, { sortOrderName } from "../constants/sortOrder";
import { useCreateActionContext } from "../contexts/CreateActionContext"
import CreateActionDialog from "../components/dialogs/ActionDialog"
import { red } from "@material-ui/core/colors";
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    csvLink: {
        visibility: "hidden",
    },
    onTheSpotBtn: {
        marginRight: theme.spacing(1),
    },
    discardButton: {
        color: red[500],
    }
}));

const csvHeaders = [
    { label: "Action Name", key: "description" },
    { label: "Site", key: "site" },
    { label: "Status", key: "statusName" },
    { label: "Due Date", key: "dueDate" },
    { label: "Module", key: "moduleName" },
    { label: "Audit Type", key: "auditType" },
    { label: "Created From Form", key: "templateName" },
    { label: "Created By", key: "createdBy" },
    { label: "Completed By", key: "completedBy" },
    { label: "Completed Date", key: "completedDate" },
    { label: "Assigned To", key: "assignedTo" },
    { label: "Priority", key: "ratingName" },
    { label: "Recommendation", key: "recommendation" },
    { label: "Latest Comment", key: "latestComment" },
];

function ActionsTableCard({
    moduleArea = null,
    summaryData,
    auditType = null,
    rating = null,
}) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const {
        reset: resetAction,
        setCreateActionDialogOpen,
        createActionDialogOpen,
    } = useCreateActionContext()
    const { selectedSite, activeAppId, appSiteIds, sites } = useSelector(
        (state) => state.account
    );
    const {
        actionsTable,
        riskAssessmentsActionsTable,
        auditActionsTable,
        incidentsActionsTable,
        complianceActionsTable,
        monitoringActionsTable,
    } = useSelector((state) => state.tablePage);
    const csvExportLinkRef = useRef();
    const [exportData, setExportData] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const contacts = useContactsForAppAndSite({ selectedSiteId: null });
    const [isActionDiscardDialogOpen, setIsActionDiscardDialogOpen] = useState(false);
    const location = useLocation();
    const { hasSafetyNestAllActionsRole } = useAuth();

    let module;
    let tableKey;

    switch (moduleArea) {
        case moduleAreas.AUDIT:
            module = auditActionsTable;
            tableKey = tablePage.AUDIT_ACTIONS_TABLE;
            break;
        case moduleAreas.RISKASSESSMENTS:
            module = riskAssessmentsActionsTable;
            tableKey = tablePage.RISK_ASSESSMENTS_ACTIONS_TABLE;
            break;
        case moduleAreas.INCIDENTS:
            module = incidentsActionsTable;
            tableKey = tablePage.INCIDENTS_ACTIONS_TABLE;
            break;
        case moduleAreas.COMPLIANCE:
            module = complianceActionsTable;
            tableKey = tablePage.COMPLIANCE_ACTIONS_TABLE;
            break;
        case moduleAreas.MONITORING:
            module = monitoringActionsTable;
            tableKey = tablePage.MONITORING_ACTIONS_TABLE;
            break;
        default:
            module = actionsTable;
            tableKey = tablePage.ACTIONS_TABLE;
    }

    const [inputFromDate, setInputFromDate] = useState(module.fromDate);
    const [inputToDate, setInputToDate] = useState(module.toDate);
    const [sortBy, setSortBy] = useState(null);
    const [orderBy, setOrderBy] = useState(sortOrderName.DESC);
    const [filteredCurrentAssignees, setFilteredCurrentAssignees] = React.useState([]);
    const [filterText, setFilterText] = useState(module.filteredText);

    useEffect(() => {
        dispatch(
            setActionsTablePage({
                ...module,
                key: tableKey,
                page: 0,
            })
        );
    }, [selectedSite]);

    const { data, isLoading, isFetching, error } = useSiteCurrentActions({
        externalIds: selectedSite
            ? [selectedSite.externalId]
            : appSiteIds[activeAppId],
        forUser: !hasSafetyNestAllActionsRole ? true : module.forUser,
        fromDate: module.fromDate,
        toDate: module.toDate,
        moduleArea: module.module,
        pageSize: module.rowsPerPage,
        pageNum: module.page + 1,
        showCompleted: module.showCompleted,
        auditType,
        rating: module.rating,
        orderByColumn: sortBy,
        sortOrder: orderBy === sortOrderName.DESC ? sortOrder.DESC : sortOrder.ASC,
        assignees: module.assignees?.map(option => option.value),
        filteredText: module.filteredText,
    });

    const handleChangePage = (event, newPage) => {
        dispatch(
            setActionsTablePage({
                ...module,
                key: tableKey,
                page: newPage,
            })
        );
    };

    const handleChangeRowsPerPage = (event) => {
        dispatch(
            setActionsTablePage({
                ...module,
                key: tableKey,
                page: 0,
                rowsPerPage: parseInt(event.target.value, 10),
            })
        );
    };

    const handleForUserChange = (event) => {
        dispatch(
            setActionsTablePage({
                ...module,
                key: tableKey,
                page: 0,
                forUser: event.target.checked,
            })
        );
    };

    const handleShowCompletedChange = (event) => {
        dispatch(
            setActionsTablePage({
                ...module,
                key: tableKey,
                page: 0,
                showCompleted: event.target.checked,
            })
        );
    };

    const handleFromDateChange = (date) => {
        setInputFromDate(date);
        if (!date || isValid(new Date(date))) {
            dispatch(
                setActionsTablePage({
                    ...module,
                    key: tableKey,
                    page: 0,
                    fromDate: date ? date.toISOString() : null,
                })
            );
        }
    };

    const handleToDateChange = (date) => {
        setInputToDate(date);
        if (!date || isValid(new Date(date))) {
            dispatch(
                setActionsTablePage({
                    ...module,
                    key: tableKey,
                    page: 0,
                    toDate: date ? date.toISOString() : null,
                })
            );
        }
    };

    const handleModuleChange = (event) => {
        dispatch(
            setActionsTablePage({
                ...module,
                key: tableKey,
                page: 0,
                module: positiveNumberOrNull(event.target.value),
            })
        );
    };

    function handleCurrentAssigneesChange(event, inputs) {
        setFilteredCurrentAssignees(inputs);
        if (inputs.length == 0) {
            dispatch(
                setActionsTablePage({
                    ...module,
                    key: tableKey,
                    page: 0,
                    assignees: [],
                }))
        }
    }

    const handleConfirmedAssigneesChange = (event, inputs) => {
        dispatch(
            setActionsTablePage({
                ...module,
                key: tableKey,
                page: 0,
                assignees: filteredCurrentAssignees,
            })
        );
    }

    const handleRatingChange = (event) => {
        if (!event) return;
        dispatch(
            setActionsTablePage({
                ...module,
                key: tableKey,
                page: 0,
                rating: positiveNumberOrNull(event.target.value),
            })
        );
    };

    const handleFilteredTextClear = () => {
        setFilterText('');
        dispatch(
            setActionsTablePage({
                ...module,
                key: tableKey,
                page: 0,
                filteredText: ''
            })
        );
    };

    const handleFilteredTextChange = (textString) => {
        setFilterText(textString);
    };

    const handleFilteredTextSearch = () => {
        dispatch(
            setActionsTablePage({
                ...module,
                key: tableKey,
                page: 0,
                filteredText: filterText
            })
        );
    };

    const handleSortByChange = (sortByField) => {
        setSortBy(sortByField);
        dispatch(
            setActionsTablePage({
                ...module,
                key: tableKey,
                page: 0,
                sortOrder: sortByField,
            })
        );
    };

    const getNameFromUserExternalId = (userExternalId, completedBy) => {
        if (
            !contacts?.data[userExternalId]?.firstName ||
            !contacts?.data[userExternalId]?.lastName
        )
            return completedBy ? "Incomplete" : "Not set";

        return `${contacts?.data[userExternalId]?.firstName} ${contacts?.data[userExternalId]?.lastName}`;
    };

    const handleClickExport = async () => {
        try {
            let actions = await actionService.downloadActionsForSite({
                externalIds: selectedSite
                    ? [selectedSite.externalId]
                    : appSiteIds[activeAppId],
                forUser: !hasSafetyNestAllActionsRole ? true : module.forUser,
                fromDate: module.fromDate,
                toDate: module.toDate,
                moduleArea: module.module,
                showCompleted: module.showCompleted,
                auditType,
                rating: module.rating,
            });
            setExportData(
                actions.map((action) => ({
                    ...action,
                    site: sites[action.siteExternalId]?.name ?? "Unknown",
                    createdBy: getNameFromUserExternalId(
                        action.createdByEmployeeExternalId, false
                    ),
                    completedBy: getNameFromUserExternalId(
                        action.completedByEmployeeExternalId, true
                    ),
                    assignedTo: getNameFromUserExternalId(
                        action.assignedEmployeeExternalId, false
                    ),
                    createdFromForm: action.templateName
                }))
            );

            setTimeout(() => {
                csvExportLinkRef.current.link.click();
            });
        } catch (e) {
            console.error(e);
            enqueueSnackbar("Could not download actions", {
                variant: "error",
            });
        }
    };

    /* Check the current url to see if the CreateAction dialog should be open on load */
    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const createActionOpenDialog = urlParams.get('createActionDialogOpen');
        if (createActionOpenDialog) {
            setCreateActionDialogOpen(true);
        }
    }, [location.search, setCreateActionDialogOpen]);


    function handleCreateActionClick() {
        setCreateActionDialogOpen(true);
    }

    function handleOnCancel() {
        setIsActionDiscardDialogOpen(false);
        resetAction();
        setCreateActionDialogOpen(false);
    }

    return (
        <CardBase
            title="Actions"
            isLoading={isLoading}
            isFetching={isFetching}
            error={error}
            rightComponent={
                <>
                    <FormControlLabel
                        control={<Switch
                            checked={module.showCompleted}
                            onChange={handleShowCompletedChange} />}
                        label="Show Completed"
                    />

                    {moduleArea !== moduleAreas.AUDIT && (
                        <Button
                            className={classes.onTheSpotBtn}
                            size="small"
                            variant="outlined"
                            color="primary"
                            onClick={handleCreateActionClick}
                        >
                            <WifiTethering className={classes.onTheSpotBtn} /> Add 'On the Spot' Action
                        </Button>
                    )}
                    <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={handleClickExport}
                    >
                        <GetApp /> Download
                    </Button>
                </>
            }
        >
            <ActionsTable
                isModule={moduleArea != null}
                actions={data?.results}
                count={data?.totalCount}
                onPageChange={handleChangePage}
                onRowsChange={handleChangeRowsPerPage}
                onModuleChange={handleModuleChange}
                onStatusChange={handleShowCompletedChange}
                onForUserChange={handleForUserChange}
                onFromDateChange={handleFromDateChange}
                onToDateChange={handleToDateChange}
                onOrderByChange={setOrderBy}
                onSortByChange={handleSortByChange}
                rows={module.rowsPerPage}
                page={module.page}
                module={module.module}
                status={module.showCompleted}
                forUser={module.forUser}
                fromDate={inputFromDate}
                toDate={inputToDate}
                sortBy={sortBy}
                orderBy={orderBy}
                summaryData={summaryData}
                rating={module.rating}
                onRatingChange={handleRatingChange}
                assignees={data?.assignees}
                filteredAssignees={filteredCurrentAssignees && filteredCurrentAssignees.length > 0 ? filteredCurrentAssignees : (module.assignees && module.assignees.length > 0 ? module.assignees : [])}
                onAssigneesChange={handleConfirmedAssigneesChange}
                handleCurrentAssigneeChange={handleCurrentAssigneesChange}
                onFilteredTextChange={handleFilteredTextChange}
                filteredText={filterText}
                clearFilteredText={handleFilteredTextClear}
                onFilteredTextSearch={handleFilteredTextSearch}
            />
            <CSVLink
                className={classes.csvLink}
                ref={csvExportLinkRef}
                headers={csvHeaders}
                data={exportData}
                filename={`${moduleArea == null ? "Dashboard" : moduleFileLabels[moduleArea]
                    }${format(new Date(), "dd/MM/yy")}.csv`}
            />
            <CreateActionDialog
                isModule={moduleArea != null}
                module={module}
                open={createActionDialogOpen}
                onCancel={() => setIsActionDiscardDialogOpen(true)}
                closeActionCreateWithoutPrompt={() => setCreateActionDialogOpen(false)}
            />
            <Dialog
                open={isActionDiscardDialogOpen}
                onClose={() => setIsActionDiscardDialogOpen(false)}
            >
                <DialogTitle>Discard 'On the Spot' Action ?</DialogTitle>
                <DialogActions>
                    <Button
                        onClick={() => setIsActionDiscardDialogOpen(false)}
                        color="primary"
                    >Cancel</Button>
                    <Button
                        className={classes.discardButton}
                        onClick={() => handleOnCancel()}
                    >Discard</Button>
                </DialogActions>
            </Dialog>
        </CardBase>
    );
}

export default ActionsTableCard;
