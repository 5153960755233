import React from 'react';
import { makeStyles, Button } from "@material-ui/core";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme) => ({
    footer: {
        padding: theme.spacing(2, 0),
        borderTop: '1px solid #E0E0E0',
        backgroundColor: '#fff',
        display: 'flex',
        justifyContent: 'center',
    },
    innerContainer: {
        width: '100%',
        maxWidth: '540px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '0 auto',
        padding: theme.spacing(0, 3),
    },
    buttonContainer: {
        display: 'flex',
        gap: theme.spacing(1),
        width: '100%',
    },
    button: {
        backgroundColor: '#6050ae',
        color: '#fff',
        height: '48px',
        flex: 1,
        '&:hover': {
            backgroundColor: '#5a4794',
        }
    },
    backButton: {
        flex: 1,
        borderColor: '#6750A4',
        color: '#6750A4',
        '&:hover': {
            borderColor: '#5a4794',
            backgroundColor: 'transparent',
        }
    },
    buttonIcon: {
        marginLeft: theme.spacing(1)
    }
}));

function Footer({ 
    showBackButton = false, 
    onNext, 
    onBack,
    nextButtonText = 'Next'
}) {
    const classes = useStyles();
    
    const handleNext = () => {
        if (onNext) {
            onNext();
        }
    };

    const handleBack = () => {
        if (onBack) {
            onBack();
        }
    };

    return (
        <footer className={classes.footer}>
            <div className={classes.innerContainer}>
                <div className={classes.buttonContainer}>
                    {showBackButton && (
                        <Button 
                            className={classes.backButton} 
                            variant="outlined" 
                            onClick={handleBack}
                            startIcon={<ArrowBackIcon />}
                        >
                            Back
                        </Button>
                    )}
                    <Button 
                        className={classes.button} 
                        variant="contained" 
                        onClick={handleNext}
                        endIcon={nextButtonText === 'Submit' ? null : <ArrowForwardIcon />}
                    >
                        {nextButtonText}
                    </Button>
                </div>
            </div>
        </footer>
    );
}

export default Footer; 