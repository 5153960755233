import React, { useState, useMemo } from "react";
import ProgressPills from '../../components/common/ProgressPills';
import { INTERNAL_REDIRECT_URL_KEY } from "../../../constants/authConfig";
import FormSubheader from './layout/FormSubHeader';

import { 
    Container, 
    makeStyles, 
    TextField,
    Button,
    Typography,
    Box,
    Divider
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Layout from './layout/Layout';

const FORM_FIELDS = {
    name: {
        label: "Name",
        type: "text",
        required: true,
        autoComplete: "name"
    },
    email: {
        label: "Email",
        type: "email",
        required: true,
        autoComplete: "email",
        inputProps: {
            maxLength: 100,
            pattern: '[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$'
        }
    },
    pageTitle: {
        color: '#1c1b1f',
        fontWeight: 600,
        fontSize: '28px',
        lineHeight: 1.29,
        textAlign: 'center',
        fontFamily: 'Inter, sans-serif'
    },
    phone: {
        label: "Phone",
        type: "tel",
        required: false,
        autoComplete: "tel"
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    },
};

const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

const useStyles = makeStyles((theme) => ({
    signInCard: {
        backgroundColor: '#f4eeff',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
    },
    signInContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            gap: theme.spacing(1),
        }
    },
    signInText: {
        [theme.breakpoints.down('xs')]: {
            '& .MuiTypography-subtitle1': {
                fontSize: '0.9rem',
            },
            '& .MuiTypography-body2': {
                fontSize: '0.75rem',
            }
        },
        color: '#483795',
    },
    signInButton: {
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1),
            minWidth: 'auto',
            '& .MuiButton-label': {
                fontSize: '0.875rem',
            }
        },
        backgroundColor: '#6050ae',
        '&:hover': {
            backgroundColor: '#5a4794',
        }
    },
    formSection: {
        paddingTop: theme.spacing(1)
    },
    textField: {
        marginBottom: theme.spacing(2),
        '& .MuiFormHelperText-root': {
            color: theme.palette.error.main
        }
    },
    pageTitle: {
        color: '#1c1b1f',
        fontWeight: 600,
        fontSize: '28px',
        lineHeight: 1.29,
        textAlign: 'center',
        fontFamily: 'Inter, sans-serif'
    },
    pageHelperText: {
        color: '#48464a',
        height: '72px',
        alignSelf: 'stretch',
        flexGrow: 0,
        fontFamily: 'Inter, sans-serif',
        fontSize: '16px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        textAlign: 'center',
    }
}));

function NonUserForm({ 
    title, 
    subtitle,
    formFields = ['name', 'email', 'phone'],
    sectionTitle = 'Your Details',
    sectionHelperText = 'Please provide your contact information so we can follow up if needed.',
    onSubmit,
    onBack,
    formData,
    setFormData,
    currentStep,
    totalSteps
}) {

    const classes = useStyles();
    const history = useHistory();
    const [errors, setErrors] = useState({});

    // Memoize field configurations
    const activeFields = useMemo(() => 
        formFields.map(fieldName => ({
            ...FORM_FIELDS[fieldName],
            name: fieldName
        })),
        [formFields]
    );

    const handleChange = (e) => {
        const { name, value } = e.target;
        let processedValue = value;

        if (name === 'phone') {
            processedValue = value.replace(/[^\d]/g, '');
        }

        setFormData(prev => ({
            ...prev,
            [name]: processedValue
        }));
    };

    const handleNext = () => {
        const emailValue = formData?.email?.trim() || '';
        const newErrors = {};

        if (!formData?.name?.trim()) {
            newErrors.name = 'Please provide a name';
        }

        if (!emailValue) {
            newErrors.email = 'Please provide an email address';
        } else if (!validateEmail(emailValue)) {
            newErrors.email = 'Please enter a valid email address';
        }

        setErrors(newErrors);
        
        if (Object.keys(newErrors).length === 0) {
            onSubmit?.(formData);
        }
    };

    const redirectToIncidentAdd = () => {
        localStorage.setItem(INTERNAL_REDIRECT_URL_KEY, '/safetynest/incidents/add');
        history.push('/');
    };

    const renderFormField = ({ name, label, type, required, inputProps, autoComplete }) => (
        <TextField
            key={name}
            label={label}
            name={name}
            type={type}
            value={formData[name] || ''}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            required={required}
            inputProps={inputProps}
            autoComplete={autoComplete}
            className={classes.textField}
            error={!!errors[name]}
            helperText={errors[name] || ''}
        />
    );

    const renderSignInSection = () => (
        <Box className={classes.signInCard}>
            <Box className={classes.signInContent}>
                <Box className={classes.signInText}>
                    <Typography 
                        variant="subtitle1" 
                        style={{ fontWeight: 600 }}
                    >
                        SafetyNest account holder?
                    </Typography>
                    <Typography variant="body" >
                        Sign into your account.
                    </Typography>
                </Box>
                <Button 
                    variant="contained"
                    color="secondary"
                    onClick={() => redirectToIncidentAdd()}
                    className={classes.signInButton}
                >
                    Sign in
                </Button>
            </Box>
        </Box>
    );

    return (
        <Layout
            onNext={handleNext}
            onBack={onBack}
            showBackButton={onBack !== undefined}
        >
            <Container maxWidth="md">
                <Box textAlign="center" mb={4} pt={3} px={3}>
                <Typography 
                    variant="h2" 
                    className={classes.pageTitle}
                    gutterBottom
                >
                    {title}
                </Typography>
                <Typography variant="body2" className={classes.pageHelperText}>
                {subtitle}
            </Typography>
            </Box>
            <Box>
                <Divider />
            </Box>                
                <Box>
                    <Divider />
                </Box>
                {(currentStep !== undefined && totalSteps !== undefined) && (
                    <div className={classes.progressContainer}>
                        <ProgressPills 
                            currentStep={currentStep} 
                            totalSteps={totalSteps} 
                        />
                    </div>
                )}
                {renderSignInSection()}
                <FormSubheader
                        title={sectionTitle}
                        helperText={sectionHelperText}
                    />   
                <Box className={classes.formSection}>                 
                    <form noValidate autoComplete="on">
                        <Box mt={3}>
                            {activeFields.map(renderFormField)}
                        </Box>
                    </form>
                </Box>
            </Container>
        </Layout>
    );
}

export default NonUserForm; 