import React from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import FormQuestion from "../../../components/formcompleter/FormQuestion";

const useStyles = makeStyles((theme) => ({
    questions: {
        width: '100%',
    },
    questionContainer: {
        marginBottom: theme.spacing(3),
    },
    sectionTitle: {
        color: '#1c1b1f',
        fontWeight: 600,
        fontSize: '16px',
        marginBottom: theme.spacing(2),
        fontFamily: 'Inter, sans-serif'
    }
}));

const NonUserFormSection = ({ section }) => {
    const classes = useStyles();

    return (
        <div className={classes.questions}>
            <Typography className={classes.sectionTitle}>
                {section.name}
            </Typography>
            <Grid container spacing={2}>
                {section.questions.map((question) => (
                    <Grid
                        item
                        key={question.id}
                        xs={12}
                        className={classes.questionContainer}
                    >
                        <FormQuestion question={question} />
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

export default NonUserFormSection; 