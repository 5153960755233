import React, { useEffect, useMemo, useState, useRef } from "react";
import CardBase from "../../../components/cards/CardBase";
import PepperStepper from "../../components/common/PepperStepper";
import { Box, Button, Container, makeStyles, Dialog, DialogContent } from "@material-ui/core";
import { useHistory, useRouteMatch } from "react-router-dom";
import IncidentDetailsForm from "../../components/incidents/IncidentDetailsForm";
import IncidentPeopleForm from "../../components/incidents/IncidentPeopleForm";
import { riddorReportableStatus, useIncidentDetails } from "../../contexts/IncidentContext";
import IncidentForm from "../../components/incidents/IncidentForm";
import { useDispatch, useSelector } from "react-redux";
import useAddIncident from "../../hooks/mutations/useAddIncident";
import useIncidentResponses from "../../hooks/queries/useIncidentResponses";
import { clearForm, initialiseQuestionActions, setExistingResponses } from "../../../redux/actions/formCompleterActions";
import { useSnackbar } from "notistack";
import IncidentInvestigationForm from "../../components/incidents/IncidentInvestigationForm";
import useUpdateIncident from "../../hooks/mutations/useUpdateIncident";
import useCalculateFormActions from "../../hooks/mutations/useCalculateFormActions";
import CreatedActions from "../../components/formcompleter/CreatedActions";
import IncidentBuilder from "../../utils/IncidentBuilder";
import CustomDialog from "../../../components/CustomDialog";
import PageBase from "../../../components/PageBase";
import SiteSelect from "../../../components/SiteSelect";
import { selectSite, setSites } from "../../../redux/actions/accountActions";
import CustomButton from "../../../components/CustomButton";
import { incidentStatuses } from "../../constants/incidentStatus";
import InvestigationNotRequiredDialog from "../../components/dialogs/InvestigationNotRequiredDialog";
import CheckCircleIcon from "@material-ui/icons/CheckCircleOutline";
import { baseRoute } from "../../routes";
import { useParams } from "react-router-dom";
import { isValid, parseISO } from "date-fns";
import useIncidentFormVersion from "../../hooks/queries/useIncidentFormVersion";
import moduleAreaConstants, { moduleUrls } from "../../constants/moduleAreaConstants";
import LoggedByExternalUser from "../../components/LoggedByExternalUser";

const useStyles = makeStyles((theme) => ({
    stepperWrapper: {
        marginBottom: theme.spacing(2),
    },
    primaryButtons: {
        display: "flex",
        gap: theme.spacing(2),
    },
    secondaryButtons: {
        display: "flex",
    },
    buttonsContainer: {
        marginTop: theme.spacing(2),
        display: "flex",
        justifyContent: "end",
        flexWrap: "wrap",
    },
    actionButton: {
        [theme.breakpoints.down("sm")]: {
            flexGrow: 1,
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        },
    },
    confirmHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
    },
    incidentLogged: {
        display: "flex",
        justifyContent: "center",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        fontSize: "1.8em", fontWeight: "bold"
    },
    incidentNumber: {
        display: "flex",
        justifyContent: "center",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        fontSize: "1.3em"
    },
    dashboardBtn: {
        display: "flex",
        justifyContent: "center",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
    },
    checkIcon: {
        color: "green",
        fontSize: "5em",
    }
}));

const SAVE_WITHOUT_INVESTIGATION = "saveWithoutInvestigation";
const COMPLETE = "complete";
const NEXT = "next";
const SAVE_PROGRESS = "saveProgress";
const NON_INVESTIGATION_FORM = "NonInvestigationForm";

const INITIAL_DETAILS_STEP = 0
const DESCRIPTION_MAX_LENGTH = 256

function AddEditIncident() {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const { params } = match;
    const { incidentId } = params;
    const { displayConfirmation } = useParams();

    const {
        setActiveStep,
        activeStep,
        isFirstStep,
        isLastStep,
        goToPreviousStep,
        goToNextStep,
        currentCardTitle,
        currentCardDescription,
        incidentType,
        incidentDate,
        incidentDescription,
        people,
        templateVersionId,
        addResponsePeople,
        isIncidentFormStep,
        setIncidentType,
        setIncidentDate,
        setIncidentDescription,
        steps,
        stepsWithInvestigation,
        stepsWithActions,
        setSteps,
        initialSteps,
        isActionsStep,
        isInvestigationStep,
        resetIncident,
        investigationStatus,
        setInvestigationStatus,
        reasonInvestigationNotRequired,
        riddorReportable,
        setRiddorReportable,
        reportingDate,
        setReportingDate,
        referenceNumber,
        setReferenceNumber,
        incidentCategoryId,
        setIncidentCategoryId,
        setTemplateVersionId,      
        setTemplateId,
        nonUser,
        setNonUser
    } = useIncidentDetails();

    const addIncident = useAddIncident();
    const updateIncident = useUpdateIncident();
    const { enqueueSnackbar } = useSnackbar();
    const { selectedSite, selectedAccount, sites } = useSelector((state) => state.account);
    const { questionResponses, uploads, actions } = useSelector((state) => state.formCompleter);
    const [isOpen, setIsOpen] = useState(false);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [newIncidentId, setNewIncidentId] = useState(null);
    const [investigationNotRequiredDialogOpen, setInvestigationNotRequiredDialogOpen] = useState(false);

    useEffect(() => { console.log({ actions }) }, [actions]);

    // Get correct version of the form when templateVersionId is ever altered (in formDetails view)
    const incidentForm = useIncidentFormVersion(templateVersionId, selectedSite?.externalId ?? "");   

    const calculateActions = useCalculateFormActions();
    
    const hasInvestigation = incidentForm?.data?.templateVersion?.investigationSections?.length > 0;

    // Gets incident with template version and form responses
    const incidentResponses = useIncidentResponses(incidentId);

    const investigationQuestionIds = useMemo(() => {
        return incidentForm?.data?.templateVersion?.investigationSections?.flatMap((s) =>
            s.questions.map((q) => q.id)
        );
    }, [incidentForm?.data?.templateVersion]);

    useEffect(() => {
        if (
            (!incidentResponses?.isFetching ||
                !incidentResponses?.isRefetching ||
                !incidentResponses?.isLoading) &&
            incidentResponses?.data?.incident?.people
        ) {
          
            addResponsePeople(incidentResponses?.data?.incident?.people);
            setIncidentDescription(incidentResponses?.data?.incident?.description);
            setTemplateVersionId(incidentResponses?.data?.incident.templateVersionId)
            setIncidentDate(incidentResponses?.data?.incident?.incidentDate);
            setIncidentType(incidentResponses?.data?.incident?.type);
            setInvestigationStatus(incidentResponses?.data?.incident?.investigationStatus);
            setTemplateId(incidentResponses?.data?.templateId);
            setRiddorReportable(incidentResponses?.data?.incident?.riddorReportable);
            setReportingDate(incidentResponses?.data?.incident?.reportingDate ?? null);
            setReferenceNumber(incidentResponses?.data?.incident?.referenceNumber);
            setIncidentCategoryId(incidentResponses?.data?.incident?.incidentCategoryId);
            setNonUser(incidentResponses?.data?.incident?.nonUser);
            // set dropdown to site
            dispatch(selectSite(sites[incidentResponses?.data?.incident.siteExternalId] ?? null));  
            dispatch(
                setExistingResponses(
                    incidentResponses?.data?.templateFormQuestionResponses
                )
            );          

        }
    }, [incidentResponses?.data]);

    useEffect(() => {

        // Set the site dropdown to the template selection by default
            
        if (
            (incidentForm?.data?.templateVersion?.investigationSections?.length > 0) &&
            (incidentForm?.data?.canCompleteIncidentInvestigation)
        )
            setSteps(stepsWithInvestigation);
        if (incidentForm?.data?.templateVersion?.investigationSections?.length === 0 || !incidentForm?.data?.canCompleteIncidentInvestigation) {
            setSteps(initialSteps);
        }
        if (activeStep > 2) {
            setActiveStep(0);
        }
    }, [incidentForm?.data?.templateVersion?.investigationSections?.length, incidentForm.data]);

    useEffect(() => {
        return () => {
            dispatch(clearForm());
            resetIncident();
        };
    }, []);

    useEffect(() => {
        if (reportingDate !== null && !isValid(reportingDate) && incidentResponses?.data?.incident?.reportingDate !== undefined) {
            setReportingDate(incidentResponses.data.incident.reportingDate);
        }

    }, [riddorReportable]);

    // Go back unless on first step, then go to incidents view
    const onBack = () => {
        if (isFirstStep) {
            dispatch(clearForm());
            resetIncident();
            history.push(`${baseRoute}/${moduleUrls[moduleAreaConstants.INCIDENTS]}`);
            return;
        }
        goToPreviousStep();
    };

    const onNext = async ({ saveWithoutInvestigation = false, reasonInvestigationNotRequired, status = investigationStatus }) => {    
        if (activeStep === INITIAL_DETAILS_STEP && incidentDescription?.length > DESCRIPTION_MAX_LENGTH) {
            enqueueSnackbar("Description must be no more than 256 characters.", { variant: "error" });
            return;
        }       
            const completedForm = new IncidentBuilder(
                uploads,
                questionResponses,
                actions,
                incidentId,
                incidentDescription,
                incidentDate,
                incidentType,
                selectedSite?.externalId,
                people,
                status,
                hasInvestigation,
                riddorReportable,
                reportingDate,
                referenceNumber,
                incidentCategoryId,
            )
                .withTemplateVersionId(templateVersionId)
                .buildIncident()
                .buildResponses();
  
            if (reasonInvestigationNotRequired) {
                completedForm.investigationNotRequired(reasonInvestigationNotRequired);
            }
          
            // Only runs if no investigationId exists
            const add = () =>           
                addIncident.mutate(completedForm.build(), {
                    onSuccess: (inc) => {
                        if (displayConfirmation && !showConfirmationDialog) {
                            setNewIncidentId(inc.id);
                            setShowConfirmationDialog(true);
                            return;
                        }
                        dispatch(clearForm());
                        resetIncident();
                        history.push(`${baseRoute}/${moduleUrls[moduleAreaConstants.INCIDENTS]}`);
                    },
                    onError: (error) => {
                        console.error(error);
                        enqueueSnackbar("Could not save incident.", { variant: "error" });
                    },
                });

        const update = () => {
            return updateIncident.mutate(completedForm.build(), {
                onSuccess: () => {
                    dispatch(clearForm());
                    resetIncident();
                    history.push(`${baseRoute}/${moduleUrls[moduleAreaConstants.INCIDENTS]}`);
                },
                onError: (error) => {
                    console.error(error);
                    enqueueSnackbar("Could not update incident.", { variant: "error" });
                },
            });
        }
            const actionCalculationRequired = completedForm.result.templateFormResponses.filter(
                x => investigationQuestionIds?.includes(x.templateQuestionId) && (x.numberValue || x.optionId || x.optionIds?.length > 0)
            ).length > 0;

            const isInvestigationsWithActionCalculation = isInvestigationStep && actionCalculationRequired;

            if (isInvestigationsWithActionCalculation) {
                const calculatedActions = await calculateActions.mutateAsync(
                    completedForm.forActionCalculation().build(),
                    {
                        onError: (error) => {
                            console.error(error);
                            enqueueSnackbar("Could not calculate actions.", { variant: "error" });
                        },
                    }
                );

                let existingActions = incidentResponses?.data?.templateFormQuestionResponses.map(x => x.actions.map(a => ({...a, templateQuestionId: x.templateQuestionId })));
                existingActions = existingActions?.reduce(function (a, b) { return a.concat(b); }, [])
                    .map(a => ({...a, employeeExternalId: a.assignedEmployeeExternalId}));              

                let existingActionPresetIds = existingActions?.map(x => x.actionPresetId);

                let actionsToManage = existingActions
                    ? [...existingActions, ...calculatedActions.filter(x => !existingActionPresetIds.includes(x.actionPresetId))]
                    : calculatedActions;      

                if (calculatedActions?.length > 0) {
                    dispatch(initialiseQuestionActions(actionsToManage));
                    setSteps(stepsWithActions);
                    setActiveStep(4);
                    return;
                }
            }

            completedForm.buildResponses(isActionsStep);

            // If we're creating a new incident
            if (!incidentId) {          
                if (completedForm.result.hasInvestigation && !incidentForm.data.canCompleteIncidentInvestigation) {
                    completedForm.result.investigationStatus = incidentStatuses.OUTSTANDING;
                }
                add();
                return;
            }

            update();    
    };

    const onClickSaveWithoutInvestigation = () => {
        for (const questionId in questionResponses) {
            const q = questionResponses[questionId];
            if (investigationQuestionIds?.includes(q.templateQuestionId) && (q.numberValue || q.optionId || q.optionIds?.length > 0)) {
                setIsOpen(true);
                return;
            }
        }
        onNext({ saveWithoutInvestigation: true });
    };
  
    const handleSubmit = (e) => {
        e.preventDefault();
        const reportingDateToCheck = typeof (reportingDate) === "string" ? parseISO(reportingDate) : reportingDate;
        if (riddorReportable === riddorReportableStatus.YES.value && reportingDateToCheck !== null && !isValid(reportingDateToCheck)) {
            return;
        }
        const { name } = e.nativeEvent.submitter;

        // Handle status based on which button clicked
        if (name === SAVE_WITHOUT_INVESTIGATION) {           
            onClickSaveWithoutInvestigation();
            return;
        }
        if (name === COMPLETE) {           
            onNext({ saveWithoutInvestigation: false, status: incidentStatuses.COMPLETED });
        }
        if (name === NEXT) {          
            goToNextStep();
        }
        if (name === SAVE_PROGRESS) {
            const status = !hasInvestigation ? incidentStatuses.NA : incidentForm?.data?.canCompleteIncidentInvestigation
                ? incidentStatuses.IN_PROGRESS
                : incidentStatuses.OUTSTANDING;
            onNext({ saveWithoutInvestigation: false, status });
        }
        if (name === NON_INVESTIGATION_FORM) {           
            onNext({ saveWithoutInvestigation: true, status: incidentStatuses.NA });
        }
    };

    function handleOnDone() {
        dispatch(clearForm());
        resetIncident();
        history.push(`${baseRoute}`);
        setShowConfirmationDialog(false);
    }

    return (
        <PageBase>
            <Container>
                <InvestigationNotRequiredDialog
                    open={investigationNotRequiredDialogOpen}
                    onCancel={() => setInvestigationNotRequiredDialogOpen(false)}
                    onSave={() =>
                        onNext({
                            saveWithoutInvestigation: true,
                            reasonInvestigationNotRequired: reasonInvestigationNotRequired,
                        })
                    }
                />
                <div className={classes.stepperWrapper}>
                    <CardBase>
                        <PepperStepper steps={steps} activeStep={activeStep} />
                    </CardBase>
                </div>
                {nonUser &&
                    <LoggedByExternalUser nonUser={nonUser} />
                }
                <form onSubmit={handleSubmit}>
                    <CardBase
                        title={currentCardTitle}
                        description={currentCardDescription}
                    >
                        {isFirstStep && <IncidentDetailsForm />}
                        {activeStep === 1 && <IncidentPeopleForm />}
                        {activeStep === 2 && ( 
                            <>
                                <Box my={2}>
                                    <SiteSelect />
                                </Box>
                                <IncidentForm incidentForm={incidentForm} />
                            </>
                        )}
                        {hasInvestigation && activeStep === 3 && (
                            <IncidentInvestigationForm incidentForm={incidentForm} />
                        )}
                        {activeStep === 4 && (
                            <CreatedActions siteExternalId={selectedSite?.externalId} />
                        )}
                    </CardBase><div>                
                    </div>
                    <div className={classes.buttonsContainer}>

                        <div className={classes.primaryButtons}>
                            <Button
                                className={classes.actionButton}
                                onClick={onBack}
                                variant="outlined"
                                color="primary"
                            >
                                Back
                            </Button>    
                            {!isLastStep && isIncidentFormStep && incidentForm?.data?.canCompleteIncidentInvestigation &&  (
                                <CustomButton
                                    className={classes.actionButton}
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    name={SAVE_PROGRESS}
                                    isLoading={addIncident.isLoading || updateIncident.isLoading}
                                    disabled={selectedSite == null}
                                >Save Progress
                                </CustomButton>
                            )}                                       
                            {!isLastStep && isIncidentFormStep && hasInvestigation && investigationStatus !== incidentStatuses.NA && incidentForm?.data?.canCompleteIncidentInvestigation && (
                                <CustomButton
                                    className={classes.actionButton}
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    name={NEXT}
                                    isLoading={addIncident.isLoading || updateIncident.isLoading}
                                    disabled={selectedSite == null}
                                >
                                    Continue to Investigation
                                </CustomButton>
                            )}
                            {!isLastStep && !isIncidentFormStep && (
                                <CustomButton
                                    className={classes.actionButton}
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    name={NEXT}
                                    isLoading={addIncident.isLoading || updateIncident.isLoading}                                   
                                >
                                    Next
                                </CustomButton>
                            )}
                            {isLastStep && activeStep !== 4 && (
                                <CustomButton
                                    className={classes.actionButton}
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    name={isInvestigationStep || !incidentForm?.data?.canCompleteIncidentInvestigation ? SAVE_PROGRESS : NON_INVESTIGATION_FORM}
                                    isLoading={addIncident.isLoading || updateIncident.isLoading}
                                    disabled={selectedSite == null}
                                >{isInvestigationStep ? "Save Progress" : "Save"}
                                </CustomButton>
                            )}
                           {/* Only show complete if on investigation step*/}
                            {isInvestigationStep && (
                                <CustomButton
                                    className={classes.actionButton}
                                    variant="contained"
                                    color="primary"
                                    type="submit"  
                                    name={isLastStep ? COMPLETE : SAVE_PROGRESS}
                                    isLoading={addIncident.isLoading || updateIncident.isLoading}
                                    disabled={selectedSite == null}
                                >
                                    {isLastStep ? "Complete" : "Save Progress"}
                                </CustomButton>
                            )}    
                           {/* Only show complete and create if on actions step*/}
                           {isActionsStep && (
                                <CustomButton
                                    className={classes.actionButton}
                                    variant="contained"
                                    color="primary"
                                    type="submit"  
                                    name={COMPLETE}
                                    isLoading={addIncident.isLoading || updateIncident.isLoading}
                                    disabled={selectedSite == null}
                                >
                                    Complete and Create Actions
                                </CustomButton>
                            )}                                                 
                        </div>
                    </div>
                </form>
                <CustomDialog
                    open={isOpen}
                    onClose={() => setIsOpen(false)}
                    confirmButtonText="Save"
                    content="Changes you have made to the Investigation will not be saved. Are you sure you want to save?"
                    title="Save without investigation?"
                    onConfirm={() => onNext({ saveWithoutInvestigation: true })}
                />
            </Container>
            <Dialog
                maxWidth="sm"
                fullWidth
                open={showConfirmationDialog}
            >
                <DialogContent dividers>
                    <div className={classes.confirmHeader}>
                        <CheckCircleIcon className={classes.checkIcon} />
                    </div>
                    <div className={classes.incidentLogged}>
                        Incident Logged
                    </div>
                    <div className={classes.incidentNumber}>
                        Incident number : {newIncidentId}
                    </div>
                    <div className={classes.dashboardBtn}>
                        <Button variant="contained" color="secondary" onClick={handleOnDone}>
                            Go to Dashboard
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </PageBase>
    );
}

export default AddEditIncident;
