import CompletedFormBuilder from "./CompletedFormBuilder";
import { incidentTypes } from "../constants/incidentType";

export class AnonymousIncidentBuilder extends CompletedFormBuilder {
    constructor(
        userDetails,
        incidentDetails,
        uploads,
        questionResponses
    ) {
        super(uploads, questionResponses, []); 
        this.userDetails = userDetails;
        this.incidentDetails = incidentDetails;
        this.questionResponses = questionResponses;
        this.people = this.transformPeople(incidentDetails.people || []);
        this.result = {};
    }

    transformPeople(people) {
        return {
            ids: people.map(person => person.id),
            details: people.reduce((acc, person) => {
                acc[person.id] = {
                    id: person.id,
                    name: person.name,
                    address: person.address,
                    isEmployee: person.isEmployee,
                    role: person.role,
                    genderType: person.gender,
                    dateOfBirth: person.dateOfBirth,
                    injuryType: person.injuryType,
                    incidentAccidentId: person.incidentAccidentId,
                    wasFirstAidProvided: person.wasFirstAidProvided,
                    injuredBodyParts: person.injuredBodyParts,
                };
                return acc;
            }, {})
        };
    }

    buildIncident() {
        this.result = {
            description: this.incidentDetails.description,
            incidentDate: this.incidentDetails.incidentDate,
            incidentType: this.incidentDetails.incidentType,
            siteExternalId: this.incidentDetails.siteExternalId,
            incidentCategoryId: this.incidentDetails.incidentCategoryId,
            templateVersionId: this.incidentDetails.templateVersionId,
            investigationStatus: this.incidentDetails.canCompleteIncidentInvestigation ? 1 : 2,
            personIdsToDelete: this.people.ids
                .filter((id) => this.people.details[id]?.toBeDeleted)
                .map((id) => this.people.details[id].id),
            persons: this.buildPersons(),
            nonUser: {
                name: this.userDetails.name,
                email: this.userDetails.email,
                phone: this.userDetails.phone
            }
        };
        return this;
    }

    buildPersons() {
        if (!this.people?.ids) {
            return [];
        }

        return this.people.ids
            .filter((id) => !this.people.details[id]?.toBeDeleted)
            .map((id) => {
                const details = this.people.details[id];
                if (!details) return null;

                return {
                    name: details.name,
                    address: details.address,
                    isEmployee: details.isEmployee,
                    position: details.role,
                    personId: details.id,
                    genderType: details.genderType,
                    dateOfBirth: details.dateOfBirth,
                    incidentAccident:
                        parseInt(this.incidentDetails.incidentType) === incidentTypes.ACCIDENT
                            ? {
                                incidentInjury: details.injuryType,
                                incidentPersonId: details.id,
                                incidentAccidentId: details.incidentAccidentId,
                                hadFirstAid: details.wasFirstAidProvided === true,
                                incidentBodyParts: Array.isArray(details.injuredBodyParts)
                                    ? details.injuredBodyParts
                                    : [],
                            }
                            : null,
                };
            })
            .filter(Boolean);
    }

    formatDateValue(dateValue) {
        if (!dateValue) return null;
        try {
            return new Date(dateValue).toISOString();
        } catch (e) {
            console.error('Error formatting date:', e);
            return null;
        }
    }

    buildResponses(isActionsStep = false) {
        this.result.templateFormResponses = Object.entries(this.questionResponses || {}).map(
            ([questionId, response]) => ({
                templateQuestionId: response.templateQuestionId,
                textValue: response.textValue || null,
                numberValue: response.numberValue || null,
                dateValue: this.formatDateValue(response.dateValue),
                optionId: response.optionId || null,
                optionIds: response.optionIds || null,
                azureFileIdsToAdd: (this.uploads[response.templateQuestionId] ?? [])
                    .filter((x) => x.toBeAdded)
                    .map((x) => x.azureFileId),
                azureFileIdsToDelete: (this.uploads[response.templateQuestionId] ?? [])
                    .filter((x) => x.toBeDeleted)
                    .map((x) => x.azureFileId),
                actions: []
            })
        );
        const unmatchedUploads = Object.keys(this.uploads)
            .filter((questionId) => !Object.values(this.responses).some(
                (r) => r.templateQuestionId === Number(questionId)
            ))
            .reduce((acc, questionId) => {
                acc[questionId] = this.uploads[questionId];
                return acc;
            }, {});

        Object.keys(unmatchedUploads)
            .filter((questionId) => unmatchedUploads[questionId]?.length > 0)
            .forEach((questionId) => {
                const uploadFiles = unmatchedUploads[questionId];
                this.result.templateFormResponses.push({
                    templateQuestionId: Number(questionId),
                    templateFormResponseId: null,
                    dateValue: null,
                    numberValue: null,
                    textValue: null,
                    optionId: null,
                    optionIds: null,
                    additionalInfo: null,
                    azureFileIdsToAdd: uploadFiles
                        .filter((x) => x.toBeAdded)
                        .map((x) => x.azureFileId),
                    azureFileIdsToDelete: uploadFiles
                        .filter((x) => x.toBeDeleted)
                        .map((x) => x.azureFileId),
                    actions: [],
                });
            });

        return this;
    }

    build() {
        return this.result;
    }
} 