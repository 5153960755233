import { useQuery } from "react-query";
import queryKeys from "../../../constants/queryKeys";
import nonUserService from "../../services/nonUserService";

function useNonUserIncidentFormVersion(templateVersionId, externalId) {
    return useQuery(
        [queryKeys.nonUserIncidentForm, templateVersionId],
        () => nonUserService.fetchIncidentFormVersion(templateVersionId, externalId),
        {
            enabled: !!templateVersionId,
            placeholderData: null
        }
    );
}

export default useNonUserIncidentFormVersion; 