import React, { useState, useEffect, useCallback } from "react";
import { Container, makeStyles, TextField, Box, Typography, Divider } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ResponsiveSelect from "../../../../components/ui/ResponsiveSelect";
import Layout from '../layout/Layout';
import { useLocation } from 'react-router-dom';
import { incidentTypeList } from "../../../constants/incidentType";
import useNonUserIncidentCategories from '../../../hooks/queries/useNonUserIncidentCategories';
import useNonUserAllEnabledModuleTemplates from '../../../hooks/queries/useNonUserAllEnabledModuleTemplates';
import moduleAreas from "../../../constants/moduleAreaConstants";
import ProgressPills from '../../../components/common/ProgressPills';
import useNonUserIncidentFormVersion from '../../../hooks/queries/useNonUserIncidentFormVersion';
import useNonUserAccountAppSites from "../../../hooks/queries/useNonUserAccountAppSites";
import PageHeader from '../layout/PageHeader';
import FormSubheader from '../layout/FormSubHeader';
import { useSelector, useDispatch } from "react-redux";
import { clearForm } from "../../../../redux/actions/formCompleterActions";

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#fff',
    },
    mainContent: {
        flex: 1,
        padding: theme.spacing(0),
        paddingBottom: theme.spacing(5),
    },
    formContent: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    formField: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    datePicker: {
        margin: 0,
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    },
}));

const maximumDate = new Date().setHours(23, 59, 59);

function IncidentDetails({ onSubmit, onBack, formData, setFormData, currentStep, totalSteps, onTabChange }) {
    const classes = useStyles();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const externalId = queryParams.get('externalId');
    const dispatch = useDispatch();
    
    const { data: incidentCategories } = useNonUserIncidentCategories();
    
    const { data: accountSitesData } = useNonUserAccountAppSites({
        parentExternalId: externalId
    });

    const siteOptions = React.useMemo(() => {
        if (!Array.isArray(accountSitesData)) return [];

        const options = [
            { value: '', label: '-- Select a Site --', disabled: true }
        ];

        accountSitesData.forEach(root => {
            root.childAccounts.forEach(child => {
                options.push({
                    value: child.externalId,
                    label: child.name 
                });
            });
        });

        return options;
    }, [accountSitesData]);

    const [incidentData, setIncidentData] = useState({
        incidentDate: new Date(),
        incidentType: '0',
        incidentCategoryId: 1,
        description: '',
        templateVersionId: '',
        siteExternalId: ''
    });

    const { data: templatesData, refetch } = useNonUserAllEnabledModuleTemplates({
        moduleArea: moduleAreas.INCIDENTS,
        accountId: externalId,
        siteExternalIds: externalId ? [externalId] : [],
        includeDisabled: "false",
    });

    const [forms, setForms] = useState([]);
    const [errors, setErrors] = useState({});

    const customOrWorknestTemplate = (templateVersionId) => {
        const template = templatesData?.find(t => t.templateVersionId === templateVersionId);
        return template?.isCustomTemplate ? 'Custom' : 'WorkNest';
    };

    const populateFormDropdown = useCallback((incidentType) => {
        if (templatesData?.length > 0) {
            const filteredTemplates = templatesData.filter(f => f.incidentType.toString() === incidentType.toString());
            const sortedResults = filteredTemplates.sort((a, b) => {
                if (!a.siteExternalId && b.siteExternalId) return -1;
                if (a.siteExternalId && !b.siteExternalId) return 1;
                return a.name.localeCompare(b.name);
            });

            const reducedArray = sortedResults.map(item => ({
                value: item.templateVersionId,
                label: item.name,
            }));

            setForms(reducedArray);

            // Always set the latest template when incident type changes
            const latestTemplate = filteredTemplates.reduce((max, current) => {
                return current.templateVersionId > (max.templateVersionId || 0) ? current : max;
            }, {});

            setIncidentData(prev => ({
                ...prev,
                templateVersionId: latestTemplate.templateVersionId ?? reducedArray[0]?.value
            }));
        }
    }, [templatesData]);

    useEffect(() => {
        if (formData) {
            // Set the incident data from form data
            setIncidentData(prev => ({
                ...prev,
                ...formData,
                incidentDate: formData.incidentDate ? new Date(formData.incidentDate) : new Date(),
            }));
            
            // Populate the form dropdown with the saved incident type
            if (formData.incidentType) {
                populateFormDropdown(formData.incidentType);
            }
        }
    }, [formData, populateFormDropdown]);

    const [isValid, setIsValid] = useState(false);
    const [currentTab, setCurrentTab] = useState(0);

    const incidentForm = useNonUserIncidentFormVersion(
        incidentData.templateVersionId,
        externalId ?? ""
    );

    useEffect(() => {
        if (templatesData?.length > 0) {
            populateFormDropdown(incidentData.incidentType);
        } else {
            refetch();
        }
    }, [templatesData, incidentData.incidentType, refetch, populateFormDropdown]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'incidentType') {
            setIncidentData(prev => ({
                ...prev,
                incidentType: value,
                templateVersionId: ''  // Reset template before populating new one
            }));
            populateFormDropdown(value);
        } else {
            setIncidentData(prev => ({
                ...prev,
                [name]: value
            }));
        }

        dispatch(clearForm());
    };

    const handleDateChange = (date) => {
        setIncidentData(prev => ({
            ...prev,
            incidentDate: date
        }));
    };

    const validateForm = useCallback(() => {
        const newErrors = {};
        
        if (!incidentData.description?.trim()) {
            newErrors.description = 'Description is required';
        }
        if (!incidentData.incidentType) {
            newErrors.incidentType = 'Incident type is required';
        }
        if (!incidentData.templateVersionId) {
            newErrors.templateVersionId = 'Form selection is required';
        }
        if (!incidentData.siteExternalId) {
            newErrors.siteExternalId = 'Site selection is required';
        }
        
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }, [incidentData]);

    const handleNext = () => {
        if (validateForm()) {
            const updatedData = {
                ...incidentData,
                incidentDate: incidentData.incidentDate.toISOString(),
                templateVersion: incidentForm?.data?.templateVersion,
                canCompleteIncidentInvestigation: incidentData.canCompleteIncidentInvestigation,
            };
            if (typeof onSubmit === 'function') {
                onSubmit(updatedData);
            }
        }
    };

    return (
        <Layout
            onNext={handleNext}
            onBack={onBack}
            showBackButton={true}
            currentStep={currentStep}
            totalSteps={totalSteps}
        >
            <Container maxWidth="md">
            <PageHeader />
                <Box >
                    <Divider />
                </Box>

                {(currentStep !== undefined && totalSteps !== undefined) && (
                    <div className={classes.progressContainer}>
                        <ProgressPills 
                            currentStep={currentStep} 
                            totalSteps={totalSteps} 
                        />
                    </div>
                )}

                <div className={classes.formContent}>
                    <FormSubheader 
                        title="Incident Details"
                        helperText="Please provide the key details of the incident."
                    />
                    <ResponsiveSelect
                        fullWidth
                        required
                        className={classes.formField}
                        label="Site"
                        options={siteOptions.map(option => ({
                            value: option.value,
                            label: option.label,
                            disabled: option.disabled
                        }))}
                        value={incidentData.siteExternalId}
                        onChange={handleChange}
                        name="siteExternalId"
                        optionValueKey="value"
                        optionLabelKey="label"
                        error={!!errors.siteExternalId}
                        helperText={errors.siteExternalId}
                    />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            required
                            className={classes.datePicker}
                            format="dd/MM/yyyy"
                            fullWidth
                            label="Incident date"
                            inputVariant="outlined"
                            value={incidentData.incidentDate}
                            onChange={handleDateChange}
                            maxDate={maximumDate}
                            maxDateMessage={"Incident date must be in the past."}
                        />
                    </MuiPickersUtilsProvider>

                    <ResponsiveSelect
                        fullWidth
                        className={classes.formField}
                        label="Incident type"
                        options={incidentTypeList}
                        value={incidentData.incidentType}
                        onChange={handleChange}
                        name="incidentType"
                        optionValueKey="value"
                        optionLabelKey="label"
                    />
                    <ResponsiveSelect
                        fullWidth
                        className={classes.formField}
                        label="Form"
                        options={forms.map(form => ({
                            ...form,
                            chipCaption: customOrWorknestTemplate(form.value)
                        }))}
                        value={incidentData.templateVersionId}
                        onChange={handleChange}
                        name="templateVersionId"
                        optionValueKey="value"
                        optionLabelKey="label"
                        error={!!errors.templateVersionId}
                        helperText={errors.templateVersionId}
                        showChipCaption={true}
                    />

                    <ResponsiveSelect
                        fullWidth
                        className={classes.formField}
                        label="Incident category"
                        options={incidentCategories ?? []}
                        optionValueKey="id"
                        optionLabelKey="description"
                        value={incidentData.incidentCategoryId || ""}
                        onChange={handleChange}
                        name="incidentCategoryId"
                    />

                    <TextField
                        required
                        variant="outlined"
                        fullWidth
                        label="Description"
                        value={incidentData.description}
                        multiline={true}
                        rows={5}
                        onChange={handleChange}
                        name="description"
                        className={classes.formField}
                        error={!!errors.description}
                        helperText={errors.description}
                    />
                </div>
            </Container>
        </Layout>
    );
}

export default IncidentDetails; 