import { useQuery } from 'react-query';
import queryKeys from '../../../constants/queryKeys';
import nonUserService from '../../services/nonUserService';

const useNonUserIncidentCategories = () => 
    useQuery(
        [queryKeys.incidentCategories],
        nonUserService.fetchIncidentCategories,
        {
            staleTime: Infinity
        }
    )
export default useNonUserIncidentCategories; 