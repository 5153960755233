class CompletedFormBuilder {
    constructor(uploads, responses, actions) {
        this.uploads = uploads;
        this.responses = responses;
        this.actions = actions;

        this.result = {};
    }
    // Cycle through responses
    buildResponses(withActions = false) {
        this.result.templateFormResponses = Object.values(this.responses).map(
            (r) => ({
                templateQuestionId: r.templateQuestionId,
                templateFormResponseId: r.templateFormResponseId,
                dateValue: r.dateValue ? new Date(r.dateValue) : null,
                numberValue: r.numberValue,
                textValue: r.textValue,
                optionId: r.optionId,
                optionIds: r.optionIds,
                additionalInfo: r.additionalInfo,
                azureFileIdsToAdd: (this.uploads[r.templateQuestionId] ?? [])
                    .filter((x) => x.toBeAdded)
                    .map((x) => x.azureFileId),
                azureFileIdsToDelete: (this.uploads[r.templateQuestionId] ?? [])
                    .filter((x) => x.toBeDeleted)
                    .map((x) => x.azureFileId),
                actions: withActions
                    ? Object.keys(this.actions)
                        .filter((actionId) => this.actions[actionId].templateQuestionId === r.templateQuestionId)
                        .map((actionId) => {
                            const {
                                dueDate,
                                actionPresetId,
                                rating,
                                employeeExternalId: assignedEmployeeExternalId,
                                existingActionId,
                            } = this.actions[actionId];

                            return {
                                dueDate: new Date(dueDate),
                                actionPresetId,
                                rating,
                                assignedEmployeeExternalId,
                                existingActionId,
                            };
                        })
                    : [],
            })
        );
        // Cycle through uploads
        const unmatchedUploads = Object.keys(this.uploads)
            .filter((questionId) => !Object.values(this.responses).some(
                (r) => r.templateQuestionId === Number(questionId)
            ))
            .reduce((acc, questionId) => {
                acc[questionId] = this.uploads[questionId];
                return acc;
            }, {});
       
        Object.keys(unmatchedUploads)
            .filter((questionId) => unmatchedUploads[questionId]?.length > 0) 
            .forEach((questionId) => {
                const uploadFiles = unmatchedUploads[questionId];
                this.result.templateFormResponses.push({
                    templateQuestionId: Number(questionId),
                    templateFormResponseId: null, 
                    dateValue: null,
                    numberValue: null,
                    textValue: null,
                    optionId: null,
                    optionIds: null,
                    additionalInfo: null,
                    azureFileIdsToAdd: uploadFiles
                        .filter((x) => x.toBeAdded)
                        .map((x) => x.azureFileId),
                    azureFileIdsToDelete: uploadFiles
                        .filter((x) => x.toBeDeleted)
                        .map((x) => x.azureFileId),
                    actions: [], 
                });
            });

        return this;
    }

    withTemplateFormId(templateFormId) {
        this.result.templateFormId = templateFormId;
        return this;
    }

    forActionCalculation() {
        this.result.templateFormResponses = this.result.templateFormResponses.filter((x) => !(!!x.textValue || !!x.dateValue));
        return this;
    }

    withTemplateVersionId(templateVersionId) {
        this.result.templateVersionId = templateVersionId;
        return this;
    }

    investigationNotRequired(reasonInvestigationNotRequired) {
        this.result.reasonInvestigationNotRequired = reasonInvestigationNotRequired
        return this
    }

    build() {
        return this.result;
    }
}

export default CompletedFormBuilder;
