import React from 'react';
import { makeStyles, Typography, Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    container: {
        marginBottom: theme.spacing(3)
    },
    sectionTitle: {
        height: '32px',
        alignSelf: 'stretch',
        flexGrow: 0,
        fontFamily: 'Inter, sans-serif',
        fontSize: '20px',
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.6,
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#1c1b1f'
    },
    helperText: {
        height: '24px',
        alignSelf: 'stretch',
        flexGrow: 0,
        fontFamily: 'Inter, sans-serif',
        fontSize: '16px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#48464a'
    }
}));

function FormSubheader({ title, helperText }) {
    const classes = useStyles();

    return (
        <Box className={classes.container}>
            <Typography 
                color="textPrimary" 
                className={classes.sectionTitle}
            >
                {title}
            </Typography>
            <Typography 
                className={classes.helperText}
            >
                {helperText}
            </Typography>
        </Box>
    );
}

export default FormSubheader;