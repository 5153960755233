import React from 'react';
import { Container, Box, Typography, makeStyles } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircleOutline";
import Layout from '../layout/Layout';

const useStyles = makeStyles((theme) => ({
    container: {
        textAlign: 'center',
        padding: theme.spacing(4),
    },
    icon: {
        fontSize: '5em',
        color: theme.palette.success.main,
        marginBottom: theme.spacing(2),
    },
    pageTitle: {
        color: '#1c1b1f',
        fontWeight: 600,
        fontSize: '28px',
        lineHeight: 1.29,
        textAlign: 'center',
        fontFamily: 'Inter, sans-serif'
    },
    reference: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(3),
        backgroundColor: theme.palette.grey[50],
        borderRadius: theme.shape.borderRadius,
        border: `1px solid ${theme.palette.grey[200]}`
    },
    referenceTitle: {
        color: '#1c1b1f',
        fontFamily: 'Inter, sans-serif',
        fontWeight: 600,
        marginBottom: theme.spacing(1)
    },
    referenceNumber: {
        color: '#1c1b1f',
        fontFamily: 'Inter, sans-serif',
        fontWeight: 600,
        fontSize: '24px'
    }
}));

function IncidentSubmittedConfirmation({ incidentId }) {
    const classes = useStyles();

    return (
        <Layout showBackButton={false} hideFooter={true}>
            <Container maxWidth="md">
                <Container maxWidth="sm" className={classes.container}>
                    <CheckCircleIcon className={classes.icon} />
                    <Typography variant="h4" className={classes.pageTitle}>
                        Incident Submitted Successfully
                    </Typography>
                    <Typography variant="body1" color="textSecondary" gutterBottom>
                        Thank you for submitting your incident report. We will review it shortly.
                    </Typography>
                    <Box className={classes.reference}>
                        <Typography variant="h6" className={classes.referenceTitle}>
                            Reference Number
                        </Typography>
                        <Typography className={classes.referenceNumber}>
                            INC-{incidentId}
                        </Typography>
                    </Box>
                    <Box mt={2}>
                        <Typography variant="body2" color="textSecondary">
                            Please keep this reference number for your records.
                        </Typography>
                    </Box>
                </Container>
            </Container>
        </Layout>
    );
}

export default IncidentSubmittedConfirmation; 