import React, { useState, useCallback } from "react";
import { Container, makeStyles, Box, Typography, Button, IconButton, Snackbar, Divider } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import PersonIcon from '@material-ui/icons/PersonAddOutlined';
import Layout from '../layout/Layout';
import ProgressPills from '../../../components/common/ProgressPills';
import PageHeader from '../layout/PageHeader';
import PeopleDialog from './PeopleDialog';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { injuryTypeList } from '../../../constants/injuryType';
import { genderTypeList } from '../../../constants/genderListConstants';
import { incidentBodyPartsList } from '../../../constants/incidentBodyParts';
import FormSubheader from '../layout/FormSubHeader';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    icon: {
        fontSize: 60,
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(2),
    },
    message: {
        textAlign: 'center',
        marginBottom: theme.spacing(1),
    },
    subMessage: {
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(3),
    },
    addButton: {
        color: '#6750A4',
        textTransform: 'none',
        marginTop: theme.spacing(2),
        fontWeight: 500,
        fontSize: '14px',
        padding: theme.spacing(1),
    },
    peopleList: {
        width: '100%',
        marginTop: theme.spacing(2),
    },
    title: {
        color: '#1c1b1f',
        fontWeight: 600,
        fontSize: '28px',
        lineHeight: 1.29,
        textAlign: 'center',
        marginBottom: theme.spacing(2)
    },
    subtitle: {
        color: theme.palette.text.secondary,
        textAlign: 'center',
        marginBottom: theme.spacing(4)
    },
    progressContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    pageTitle: {
        color: '#1c1b1f',
        fontWeight: 600,
        fontSize: '28px',
        lineHeight: 1.29,
        textAlign: 'center',
        fontFamily: 'Inter, sans-serif'
    },
    personCard: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #79747E',
        borderRadius: '12px',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(3),
    },
    personHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing(3),
    },
    personName: {
        fontSize: '16px',
        fontWeight: 600,
        color: '#1C1B1F',
        fontFamily: 'Inter, sans-serif',
    },
    actionButtons: {
        display: 'flex',
        gap: theme.spacing(1),
    },
    detailRow: {
        display: 'flex',
        marginBottom: theme.spacing(2),
    },
    detailLabel: {
        width: '140px',
        color: '#1C1B1F',
        fontSize: '14px',
        fontWeight: 500,
        fontFamily: 'Inter, sans-serif',
        fontStyle: 'normal',
    },
    detailValue: {
        flex: 1,
        color: '#1C1B1F',
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: 'Inter, sans-serif',
        fontStyle: 'normal',
    },
    divider: {
        margin: theme.spacing(2, 0),
        backgroundColor: '#79747E',
    },
    iconButton: {
        padding: theme.spacing(1),
        color: '#49454F',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
    },
    emptyStateIcon: {
        backgroundColor: '#F3F0FF',
        borderRadius: '50%',
        width: 80,
        height: 80,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 auto',
        marginBottom: theme.spacing(2)
    },
    emptyStateText: {
        fontWeight: 500,
        marginBottom: theme.spacing(1),
        color: theme.palette.text.primary
    },
    emptyStateSubtext: {
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(3)
    },
    emptyStateAddButton: {
        border: '1px solid #79747E',
        padding: theme.spacing(1, 2),
        width: '100%',
        color: '#6750A4',
        textTransform: 'none',
        fontWeight: 500,
        fontSize: '14px',
    }
}));

function PeopleForm({ formData, setFormData, currentStep, totalSteps, errors }) {
    const classes = useStyles();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [editingPerson, setEditingPerson] = useState(null);
    const [error, setError] = useState(false);
    const people = formData?.people || [];

    const handleAddPerson = (personData) => {
        if (editingPerson) {
            // Update existing person
            setFormData(prev => ({
                ...prev,
                people: prev.people.map(person => 
                    person.id === editingPerson.id ? { ...personData, id: person.id } : person
                )
            }));
            setEditingPerson(null);
        } else {
            // Add new person
            setFormData(prev => ({
                ...prev,
                people: [...(prev.people || []), {
                    id: Date.now(),
                    ...personData
                }]
            }));
        }
        setError(false); // Clear error when person is added
    };

    const handleEditPerson = (person) => {
        setEditingPerson(person);
        setDialogOpen(true);
    };

    const handleDeletePerson = (personId) => {
        setFormData(prev => ({
            ...prev,
            people: prev.people.filter(person => person.id !== personId)
        }));
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setEditingPerson(null);
    };

    return (
        <Container maxWidth="md">
            <PageHeader />
            <div className={classes.progressContainer}>
                <ProgressPills 
                    currentStep={currentStep} 
                    totalSteps={totalSteps} 
                />
            </div>
            <FormSubheader 
                title="People"
                helperText="Tell us about the people involved."
            />
            <div className={classes.container}>
                {people.length === 0 ? (
                    <Box textAlign="center">
                        <Box className={classes.emptyStateIcon}>
                            <PersonIcon style={{ 
                                color: '#6750A4',
                                fontSize: 40  // Increased icon size
                            }} />
                        </Box>
                        <Typography className={classes.emptyStateText}>
                            No people added yet...
                        </Typography>
                        <Typography className={classes.emptyStateSubtext}>
                            Please add details of any person involved.
                        </Typography>
                        <Button
                            variant="outlined"
                            onClick={() => setDialogOpen(true)}
                            className={classes.emptyStateAddButton}
                            startIcon={<span style={{ fontSize: '20px', color: '#6750A4' }}>+</span>}
                        >
                            Add person
                        </Button>
                    </Box>
                ) : (
                    <div className={classes.peopleList}>
                        {people.map((person) => (
                            <Box key={person.id} className={classes.personCard}>
                                <Box className={classes.personHeader}>
                                    <Typography className={`${classes.personName}`}>
                                        {person.name}
                                    </Typography>
                                    <Box className={classes.actionButtons}>
                                        <IconButton 
                                            className={classes.iconButton}
                                            size="small" 
                                            onClick={() => handleEditPerson(person)}
                                        >
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                        <IconButton 
                                            className={classes.iconButton}
                                            size="small" 
                                            onClick={() => handleDeletePerson(person.id)}
                                        >
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    </Box>
                                </Box>

                                <Box className={classes.detailRow}>
                                    <Typography className={classes.detailLabel}>Gender:</Typography>
                                    <Typography className={classes.detailValue}>
                                        {genderTypeList.find(g => g.value === person.gender)?.label}
                                    </Typography>
                                </Box>

                                {person.dateOfBirth && (
                                    <Box className={classes.detailRow}>
                                        <Typography className={classes.detailLabel}>Date of birth:</Typography>
                                        <Typography className={classes.detailValue}>
                                            {new Date(person.dateOfBirth).toLocaleDateString('en-GB', {
                                                day: '2-digit',
                                                month: 'short',
                                                year: 'numeric'
                                            })}
                                        </Typography>
                                    </Box>
                                )}

                                {person.address && (
                                    <Box className={classes.detailRow}>
                                        <Typography className={classes.detailLabel}>Address:</Typography>
                                        <Typography className={classes.detailValue}>
                                            {person.address}
                                        </Typography>
                                    </Box>
                                )}

                                <Box className={classes.detailRow}>
                                    <Typography className={classes.detailLabel}>Employee:</Typography>
                                    <Typography className={classes.detailValue}>
                                        {person.isEmployee ? `Yes - ${person.role || 'No role specified'}` : 'No'}
                                    </Typography>
                                </Box>

                                <Divider className={classes.divider} />

                                {person.injuryType && (
                                    <Box className={classes.detailRow}>
                                        <Typography className={classes.detailLabel}>Injury type:</Typography>
                                        <Typography className={classes.detailValue}>
                                            {injuryTypeList.find(i => i.value === person.injuryType)?.label}
                                        </Typography>
                                    </Box>
                                )}

                                {person.injuredBodyParts && person.injuredBodyParts.length > 0 && (
                                    <Box className={classes.detailRow}>
                                        <Typography className={classes.detailLabel}>Injured body parts:</Typography>
                                        <Typography className={classes.detailValue}>
                                            {person.injuredBodyParts
                                                .map(part => incidentBodyPartsList.find(b => b.value === part)?.label)
                                                .filter(Boolean)
                                                .join(', ')}
                                        </Typography>
                                    </Box>
                                )}

                                <Box className={classes.detailRow}>
                                    <Typography className={classes.detailLabel}>First aid:</Typography>
                                    <Typography className={classes.detailValue}>
                                        {person.wasFirstAidProvided ? 'Yes' : 'No'}
                                    </Typography>
                                </Box>
                            </Box>
                        ))}
                        <Box textAlign="center">
                            <Button
                                variant="text"
                                onClick={() => setDialogOpen(true)}
                                className={classes.addButton}
                                startIcon={<span style={{ fontSize: '20px', color: '#6750A4' }}>+</span>}
                            >
                                Add another person
                            </Button>
                        </Box>
                    </div>
                )}
            </div>
            <PeopleDialog
                open={dialogOpen}
                onClose={handleCloseDialog}
                onSave={handleAddPerson}
                initialData={editingPerson}
                genderTypeList={genderTypeList}
                injuryTypeList={injuryTypeList}
                incidentBodyPartsList={incidentBodyPartsList}
            />
        </Container>
    );
}

function PeopleDetails({ 
    formData, 
    setFormData, 
    onSubmit, 
    onBack,
    currentStep,
    totalSteps 
}) {
    const [showError, setShowError] = useState(false);
    const [errors, setErrors] = useState({});

    const validateForm = useCallback(() => {
        const newErrors = {};
        const people = formData?.people || [];
        
        if (people.length === 0) {
            newErrors.people = 'At least one person is required';
        }
        
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }, [formData]);

    const handleSubmit = () => {
        const isFormValid = validateForm();
        if (isFormValid) {
            onSubmit({
                people: formData?.people || []
            });
        } else {
            setShowError(true);
        }
    };

    return (
        <Layout
            onNext={handleSubmit}
            onBack={onBack}
            showBackButton={true}
            currentStep={currentStep}
            totalSteps={totalSteps}
        >
            <PeopleForm 
                formData={formData}
                setFormData={setFormData}
                currentStep={currentStep}
                totalSteps={totalSteps}
                errors={errors}
            />
            {showError && (
                <Snackbar open={showError} autoHideDuration={6000} onClose={() => setShowError(false)}>
                    <Alert severity="error">Please add at least one person</Alert>
                </Snackbar>
            )}
        </Layout>
    );
}

export default PeopleDetails; 