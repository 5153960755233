import React, { useRef, useState, useEffect } from "react";
import CardBase from "../../components/cards/CardBase";
import {
    alpha,
    Button,
    Chip,
    CircularProgress,
    IconButton,
    makeStyles,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Typography,
    Grid,
    InputAdornment,
    TextField,
    Tooltip,
} from "@material-ui/core";
import { isValid } from "date-fns";
import EnhancedTableHead from "../../components/table/EnhancedTableHead";
import DeleteIncidentDialog from "./dialogs/DeleteIncidentDialog";
import { useDispatch, useSelector } from "react-redux";
import useIncidentsForSites from "../hooks/queries/useIncidentsForSites";
import clsx from "clsx";
import { Link, useRouteMatch, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { baseRoute } from "../routes";
import incidentService from "../services/incidentService";
import moduleAreaConstants, {
    moduleUrls,
} from "../constants/moduleAreaConstants";
import { viewModes } from "../../constants/viewModeConstants";
import { setIncidentsTablePage } from "../../redux/actions/tablePageActions";
import { tablePage } from "../constants/tablePageConstants";
import { downloadFileFromTypedBlob } from "../../utils/fileUtils";
import {
    GetApp, MoreVert, DeleteOutline,
    Search as SearchIcon,
    Clear as ClearIcon,

} from "@material-ui/icons";
import { CSVLink } from "react-csv";
import { formatShortDate } from "../../utils/dateTimeUtils";
import { trimTextToLength } from "../../utils/stringUtils";
import { sortOrderName } from "../constants/sortOrder";
import { genderTypeList } from "../constants/genderListConstants";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {riddorReportableStatus} from "../contexts/IncidentContext";
import ResponsiveSelect from "../../components/ui/ResponsiveSelect";
import useIncidentCategories from "../hooks/queries/useIncidentCategories";
import qrCodeService from "../services/qrCodeService";
import QRCodeDialog from '../components/dialogs/QRCodeDialog';

const headCells = [
    {
        id: "id",
        label: "ID",
        isSortable: true,
    },
    {
        id: "site",
        label: "Site",
        isSortable: true,
    },
    {
        id: "description",
        label: "Description",
        isSortable: true,
    },
    {
        id: "injuredparty",
        label: "Injured Party",
        isSortable: true,
    },
    {
        id: "date",
        label: "Date of Incident",
        isSortable: true,
    },
    {
        id: "lastupdated",
        label: "Last Updated",
        isSortable: true,
    },
    {
        id: "type",
        label: "Type",
        isSortable: true,
    },
    {
        id: "category",
        label: "Category",
        isSortable: true,
    },
    {
        id: "riddor",
        label: "RIDDOR",
        isSortable: true,  
    },
    {
        id: "investigationstatus",
        label: "Investigation Status",
        isSortable: true,
    },
    { id: "menu", label: "" },
];

const useStyles = makeStyles((theme) => ({
    statusChip: {
        color: theme.palette.getContrastText(theme.palette.neutralGrey),
        borderRadius: "4px",
        backgroundColor: theme.palette.neutralGrey,
        "&.status-0": {
            backgroundColor: theme.palette.successGreen,
            color: theme.palette.getContrastText(theme.palette.successGreen),
        },
        "&.status-1": {
            backgroundColor: theme.palette.errorRed,
            color: theme.palette.getContrastText(theme.palette.errorRed),
        },
        "&.status-2": {
            backgroundColor: theme.palette.neutralGrey,
            color: theme.palette.getContrastText(theme.palette.neutralGrey),
        },
        "&.status-dri": {
            backgroundColor: "#E4DEFC",
            color: "black",
        },
    },
    tableRow: {
        cursor: "pointer",
        "&:hover": {
            backgroundColor: alpha(theme.palette.secondary.main, 0.08),
        },
    },
    downloadIncident: {
        textAlign: "center",
    },
    csvLink: {
        visibility: "hidden",
    },
    downloadAllIncidents: {
        marginRight: theme.spacing(1),
    },
    menuItem: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        display: "flex",
        gap: theme.spacing(1),
        fontSize: "0.875rem",
    },

    popMenuIcon: {
        marginRight: theme.spacing(1),
    },
    driTableRow: {
        cursor: "default",
    },
    toolbar: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    searchField: {
        marginLeft: 'auto',
    },
    searchTextField: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        width: "100%",
        '& .clearIcon': {
            visibility: 'hidden',
        },
        '&:hover .clearIcon': {
            visibility: 'visible',
        },
    },
    endAdornment: {
        paddingRight: theme.spacing(1),
    },
    tooltip: {
        margin: theme.spacing(1),
        backgroundColor: 'white',
        border: '1px solid #000',
        color: theme.palette.common.black,
        fontSize: '0.9em',
        borderRadius: 0,
        padding: theme.spacing(1),
    },

}));

const csvHeaders = [
    { label: "Id", key: "id" },
    { label: "Site", key: "site" },
    { label: "Description", key: "description" },
    { label: "Date of Incident", key: "dateOfIncident" },
    { label: "Last Updated", key: "dateLastUpdated" },
    { label: "Type", key: "typeDescription" },
    { label: "Investigation Status", key: "investigationStatusDescription" },
    { label: "Logged By", key: "loggedBy" },
    { label: "Logged Date", key: "loggedDate" },
    { label: "Name of Person", key: "nameOfPerson" },
    { label: "DOB", key: "dob" },
    { label: "Gender", key: "gender" },
    { label: "Role", key: "role" },
    { label: "Is Employee?", key: "isEmployee" },
    { label: "Injury Type", key: "injuryType" },
    { label: "Injured Body Part", key: "injuredBodyPart" },
    { label: "First Aid Provided?", key: "firstAidProvided" },
    { label: "RIDDOR", key: "riddorReportableDescription" },
    { label: "Category", key: "incidentCategoryDescription" },
];

const tableKey = tablePage.INCIDENTS_TABLE;

function IncidentsTableCard() {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const [exportData, setExportData] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [incidentMenuOpen, setIncidentMenuOpen] = useState(false);
    const [isPdfLoading, setIsPdfLoading] = useState(false);

    const csvExportLinkRef = useRef();

    const { path } = useRouteMatch();
    const { enqueueSnackbar } = useSnackbar();

    const { selectedSite, activeAppId, appSiteIds, sites, selectedAccount } = useSelector(
        (state) => state.account
    );
    const { incidentsTable } = useSelector((state) => state.tablePage);

    const [filterText, setFilterText] = useState(incidentsTable?.filteredText);
    const [filterPersonText, setFilterPersonText] = useState(incidentsTable?.filteredPersonText);
    const [inputFromDate, setInputFromDate] = useState(incidentsTable?.fromDate);
    const [inputToDate, setInputToDate] = useState(incidentsTable?.toDate);
    const [riddorFilterValue, setRiddorFilterValue] = useState(null);
    const [searchRequested, setSeachRequested] = React.useState(false);
    const [searchNameRequested, setSeachNameRequested] = React.useState(false);
    const [categoryFilterValue, setCategoryFilterValue] = useState(null);

    const siteExternalIds = selectedSite
        ? [selectedSite.externalId]
        : appSiteIds[activeAppId];
    
        useEffect(() => {
        dispatch(
            setIncidentsTablePage({
                ...incidentsTable,
                key: tableKey,
                page: 0,
            })
        );
    }, [selectedSite]);
    
    const incidents = useIncidentsForSites({
        externalIds: siteExternalIds,
        pageNum: incidentsTable.page,
        numPerPage: incidentsTable.rowsPerPage,
        orderByColumn: incidentsTable.sortBy || headCells[3].id,
        sortOrder: incidentsTable.orderBy || sortOrderName.DESC,
        filteredText: incidentsTable.filteredText,
        fromDate: incidentsTable.fromDate,
        toDate: incidentsTable.toDate,
        filteredPersonText: incidentsTable.filteredPersonText,
        riddorReportable: incidentsTable.riddorReportable,
        incidentCategoryId: incidentsTable.incidentCategoryId,
    });

    const { data: incidentCategories } = useIncidentCategories();

    const handleRowClick = (event, incident) => {
        event.stopPropagation();
        if (incident.isDriIncident) return;

        history.push(
            `${baseRoute}/${moduleUrls[moduleAreaConstants.INCIDENTS]}/${incident.id
            }/${viewModes.EDIT}`
        );
    };

    const handleRowsPerPageChange = (e) => {
        dispatch(
            setIncidentsTablePage({
                ...incidentsTable,
                key: tableKey,
                page: 0,
                rowsPerPage: parseInt(e.target.value, 10),
            })
        );
    };

    const handlePageChange = (e, newPage) => {
        dispatch(
            setIncidentsTablePage({
                ...incidentsTable,
                key: tableKey,
                page: newPage,
                rowsPerPage: incidentsTable.rowsPerPage,
            })
        );
    };

    const handleSortClick = (_, sortColumn) => {
        if (incidents.data && incidents.data.results.length > 0) {
            
            const isAsc = incidentsTable.sortBy === sortColumn && incidentsTable.orderBy === sortOrderName.ASC;
            dispatch(
                setIncidentsTablePage({
                    ...incidentsTable,
                    key: tableKey,
                    page: 0,
                    rowsPerPage: incidentsTable.rowsPerPage,
                    sortBy: sortColumn,
                    orderBy: isAsc ? sortOrderName.DESC : sortOrderName.ASC,
                })
            );
        }
    };

    const handleClickExport = async () => {
        try {
            let incidents = await incidentService.downloadIncidentsForSite({
                siteExternalIds: selectedSite ? [selectedSite.externalId] : appSiteIds[activeAppId],
                filteredText: incidentsTable.filteredText,
                fromDate: incidentsTable.fromDate,
                toDate: incidentsTable.toDate,
                filteredPersonText: incidentsTable.filteredPersonText,
                riddorReportable: riddorFilterValue,
                incidentCategoryId: categoryFilterValue,
            });

            setExportData(
                incidents.map((form) => {
                    let person = form.people[0] || {};
                    let accident = person.incidentAccident || {};

                    return {
                        ...form,
                        site: sites[form.siteExternalId]?.name ?? "Unknown",
                        dateOfIncident: formatShortDate(form.incidentDate),
                        dateLastUpdated: formatShortDate(form.lastUpdatedDate),
                        loggedDate: form.createdDate && formatShortDate(form.createdDate),
                        dob: person.dateOfBirth && formatShortDate(person.dateOfBirth),
                        nameOfPerson: person.name,
                        gender: genderTypeList.find(x => x.value === person.genderType)?.label,
                        role: person.position,
                        isEmployee: person.isEmployee ? "Y": "N",
                        injuredBodyPart: accident.injuries?.map(i => i.incidentBodyPartDescription).join(","),
                        injuryType: accident.incidentInjuryDescription,
                        firstAidProvided: accident.hadFirstAid === true ? "Y" : accident.hadFirstAid === false ? "N" : ""
                    };
                })
            );

            setTimeout(() => {
                csvExportLinkRef.current.link.click();
            });
        } catch (e) {
            console.error(e);
            enqueueSnackbar("Could not download incidents content", {
                variant: "error",
            });
        }
    };

    const handleClick = (e, id) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
        setIncidentMenuOpen(id);
    };

    const handleClose = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
        setIncidentMenuOpen(null);
    };

    const [archiveIncidentId, setArchiveIncidentId] = useState(null);
    const [isDri, setIsDri] = useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    const openDeleteFormPopup = (e, form) => {
        e.stopPropagation();
        setArchiveIncidentId(form.id);
        setIsDri(form.isDriIncident);
        setIsDeleteOpen(true);
        setAnchorEl(null);
        setIncidentMenuOpen(false);
    };

    const handleDownloadIncidentPdf = async (e, incident) => {
        e.stopPropagation();
        setIsPdfLoading(true);

        try {
            const response = await incidentService.downloadIncidentPdf(incident.id, incident.isDriIncident);
            const filename = `Incident - ${incident.id.toString()}`;       
                     
            downloadFileFromTypedBlob(response, filename);
        } catch (e) {
            console.error(e);
            enqueueSnackbar("Could not download incident pdf", { variant: "error" });
        } finally {
            setIsPdfLoading(false);
            setIncidentMenuOpen(null);
            setAnchorEl(null);
        }
    };

    const handleToDateChange = (date) => {
        setInputToDate(date);
        if (!date || isValid(new Date(date))) {
            dispatch(
                setIncidentsTablePage({
                    ...incidentsTable,
                    key: tableKey,
                    page: 0,
                    toDate: date ? date.toISOString() : null,
                })
            );
        }
    };

    const handleFromDateChange = (date) => {
        setInputFromDate(date);
        if (!date || isValid(new Date(date))) {
            dispatch(
                setIncidentsTablePage({
                    ...incidentsTable,
                    key: tableKey,
                    page: 0,
                    fromDate: date ? date.toISOString() : null,
                })
            );
        }
    };

    function handleSearchChange(e) {
        setSeachRequested(false);
        onFilteredTextChange(e.target.value)
    }

    const handleSearchClick = () => {
        setSeachRequested(true);
        onFilteredTextSearch();
    };

    const showFilteredTextError = () => {
        return (filterText && filterText.length > 0 && filterText.length < 4 && searchRequested)
    }

    const onFilteredTextChange = (textString) => {
        setFilterText(textString);
    };

    const onFilteredTextSearch = () => {
        dispatch(
            setIncidentsTablePage({
                ...incidentsTable,
                key: tableKey,
                page: 0,
                filteredText: filterText
            })
        );
    };

    const handleFilteredTextClear = () => {
        setFilterText('');
        dispatch(
            setIncidentsTablePage({
                ...incidentsTable,
                key: tableKey,
                page: 0,
                filteredText: ''
            })
        );
    };
    
    const handleChangeRiddorFilterValue = (value) => {
        setRiddorFilterValue(value);
        dispatch(
            setIncidentsTablePage({
                ...incidentsTable,
                key: tableKey,
                page: 0,
                riddorReportable: value
            })
        )
    }
    
    const handleChangeCategoryFilterValue = (value) => {
        setCategoryFilterValue(value);
        dispatch(
            setIncidentsTablePage({
                ...incidentsTable,
                key: tableKey,
                page: 0,
                incidentCategoryId: value
            })
        )
    }

    function handleKeyDown(event) {

        if (event.key === 'Enter') {
            handleSearchClick();
        }
    }

    function handleNameSearchChange(e) {
        setSeachNameRequested(false);
        onFilteredNameTextChange(e.target.value)
    }

    const handleNameSearchClick = () => {
        setSeachNameRequested(true);
        onFilteredNameTextSearch();
    };

    const showNameFilteredTextError = () => {
        return (filterPersonText && filterPersonText.length > 0 && filterPersonText.length < 4 && searchNameRequested)
    }

    const onFilteredNameTextChange = (textString) => {
        setFilterPersonText(textString);
    };

    const onFilteredNameTextSearch = () => {
        dispatch(
            setIncidentsTablePage({
                ...incidentsTable,
                key: tableKey,
                page: 0,
                filteredPersonText: filterPersonText
            })
        );
    };

    const handleNameFilteredTextClear = () => {
        setFilterPersonText('');
        dispatch(
            setIncidentsTablePage({
                ...incidentsTable,
                key: tableKey,
                page: 0,
                filteredPersonText: ''
            })
        );
    };

    function handleNameKeyDown(event) {

        if (event.key === 'Enter') {
            handleNameSearchClick();
        }
    }

    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const open2 = Boolean(menuAnchorEl);
    const [qrCodeDialogOpen, setQRCodeDialogOpen] = useState(false);
    const [qrCodeByteArray, setQRCodeByteArray] = useState(null);
 
    const generateQRCode = async () => {       
        let url = window.location.href.replace(/incidents$/, "") + "report-incident?externalId=" + selectedAccount.account.externalId;

        let response = await qrCodeService.createQRCode(url);

        setQRCodeByteArray(response);
        setQRCodeDialogOpen(true);       
    };
    const handleQRCodeDialogClose = () => {
        setQRCodeDialogOpen(false);
    };
    const handleMenuToggle = (event) => {
        setMenuAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setMenuAnchorEl(null);
    };

    const tableContent = () => {
        if (incidents.data && incidents.data.results.length > 0) {
            return incidents.data.results.map((incident) => (
                <TableRow
                    className={clsx(classes.tableRow, {
                        [classes.driTableRow]: incident.isDriIncident,
                    })}
                    key={incident.id}
                    onClick={(event) => handleRowClick(event, incident)}
                >
                    <TableCell>
                        {incident.isDriIncident ? (
                            <div>
                                {" "}
                                <Chip
                                    className={clsx(classes.statusChip, `status-dri`)}
                                    size="small"
                                    label="Historic"
                                />
                                {incident.driReference}{" "}
                            </div>
                        ) : (
                            incident.id
                        )}{" "}
                    </TableCell>
                    <TableCell>
                        {sites[incident.siteExternalId]?.name ?? "Unknown"}
                    </TableCell>
                    <TableCell>{trimTextToLength(incident.description, 100)}</TableCell>
                    <TableCell>  {incident.people.length > 0 ? (<>
                        {incident.people[0].name} {incident.people.length > 1 ? ` +${incident.people.length - 1}` : ''}</>)
                        : ('No Record')}
                    </TableCell>
                    <TableCell>{formatShortDate(incident.incidentDate)}</TableCell>
                    <TableCell>{formatShortDate(incident.lastUpdatedDate)}</TableCell>
                    <TableCell>{incident.typeDescription}</TableCell>
                    <TableCell>{incident.incidentCategoryDescription}</TableCell>
                    <TableCell>{Object.values(riddorReportableStatus).filter(x => x.value === incident.riddorReportable)[0]?.label ?? "N/A"}</TableCell>
                    <TableCell>
                        <Chip
                            className={clsx(
                                classes.statusChip,
                                `status-${incident.investigationStatus}`
                            )}
                            size="small"
                            label={incident.investigationStatusDescription || "N/A"}
                        />
                    </TableCell>
                    <TableCell>
                        {isPdfLoading && incidentMenuOpen === incident.id ? (
                            <CircularProgress size={24} />
                        ) : (
                            <>
                                <IconButton onClick={(e) => handleClick(e, incident.id)}>
                                    <MoreVert />
                                </IconButton>
                                <Menu
                                    keepMounted
                                    open={incidentMenuOpen === incident.id}
                                    onClose={handleClose}
                                    anchorEl={anchorEl}
                                >
                                    <MenuItem
                                        onClick={(e) => handleDownloadIncidentPdf(e, incident)}
                                    >
                                        <GetApp className={classes.popMenuIcon} /> Download
                                    </MenuItem>
                                    <MenuItem onClick={(e) => openDeleteFormPopup(e, incident)}>
                                        <DeleteOutline className={classes.popMenuIcon} /> Delete
                                    </MenuItem>
                                </Menu>
                            </>
                        )}
                    </TableCell>
                </TableRow>
            ));
        }

        return (
            <TableRow>
                <TableCell colSpan={headCells.length}>
                    <Typography>
                        No incidents for selected site{selectedSite ? "." : "s."}
                    </Typography>
                </TableCell>
            </TableRow>
        );
    };

    return (
        <CardBase
            title="Logged Incidents"
            description="Recently updated incidents"
            isLoading={incidents.isLoading}
            isFetching={incidents.isFetching}
            error={incidents.error}
            fullHeight
            rightComponent={
                <>
                    <IconButton
                        aria-label="QR menu"
                        aria-controls="qr-menu"
                        aria-haspopup="true"
                        onClick={handleMenuToggle}
                        color="inherit"
                    >
                        <MoreVert />
                    </IconButton>
                    <Button
                        className={classes.downloadAllIncidents}
                        variant="outlined"
                        color="primary"
                        onClick={handleClickExport}
                    >
                        <GetApp /> Download
                    </Button>
                    <Button
                        component={Link}
                        to={`${path}/${viewModes.ADD}`}
                        variant="contained"
                        color="primary"
                    >
                        Add Incident
                    </Button>
                    <Menu
                        id="qr-menu"
                        anchorEl={menuAnchorEl}
                        getContentAnchorEl={null}
                        keepMounted
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        open={open2}
                        onClose={handleCloseMenu}
                        onClick={handleCloseMenu}
                    >
                        <MenuItem onClick={generateQRCode}>
                            Generate QR Code
                        </MenuItem>
                    </Menu>
                    <QRCodeDialog open={qrCodeDialogOpen} onClose={handleQRCodeDialogClose} image={qrCodeByteArray} headerText="Scan me!" subText="To log an incident that has occured" fileName="logAnIncident.png"/>
                </>
            }
        >                       
            <Grid container spacing={1} >
                <Grid item xs={12} lg={3} className={classes.toolbar}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            fullWidth
                            size="small"
                            inputVariant="outlined"
                            label="From date"
                            format="dd/MM/yyyy"
                            value={inputFromDate || null}
                            onChange={(date) => handleFromDateChange(date)}
                            maxDate={inputToDate}
                            KeyboardButtonProps={{ className: classes.keyboardBtn }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} lg={3} className={classes.toolbar}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            fullWidth
                            size="small"
                            inputVariant="outlined"
                            label="To date"
                            format="dd/MM/yyyy"
                            value={inputToDate || null}
                            onChange={(date) => handleToDateChange(date)}
                            minDate={inputFromDate}
                            KeyboardButtonProps={{ className: classes.keyboardBtn }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} lg={3} className={classes.toolbar}>
                    <ResponsiveSelect
                        options={incidentCategories ?? []}
                        optionValueKey="id"
                        optionLabelKey="description"
                        label="Category"
                        value={categoryFilterValue}
                        onChange={e => handleChangeCategoryFilterValue(e.target.value)}
                        fullWidth
                        size="small"
                        
                    />
                </Grid>
                <Grid item xs={12} lg={3} className={classes.toolbar}>
                    <ResponsiveSelect
                        options={Object.values(riddorReportableStatus)}
                        optionValueKey="value"
                        optionLabelKey="label"
                        label="RIDDOR"
                        value={riddorFilterValue}
                        onChange={e => handleChangeRiddorFilterValue(e.target.value)}
                        fullWidth
                        size="small"

                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12} lg={6}>
                    <TextField
                        className={classes.searchTextField}
                        label="Search Injured Party Name"
                        variant="outlined"
                        size="small"
                        value={filterPersonText || ""}
                        onChange={handleNameSearchChange}
                        helperText='Please enter at least 4 character to search on'
                        error={showNameFilteredTextError()}
                        FormHelperTextProps={{ hidden: !showNameFilteredTextError() }}
                        onKeyDown={handleNameKeyDown}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {filterPersonText && (
                                        <Tooltip title="Clear" placement="bottom-end" classes={{ tooltip: classes.tooltip }}>
                                            <IconButton className={classes.iconBtn}>
                                                <ClearIcon className={`clearIcon ${classes.clearIcon}`}
                                                           onClick={handleNameFilteredTextClear}
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    <Tooltip title="Search Name" placement="bottom-end" classes={{ tooltip: classes.tooltip }}>
                                        <IconButton className={classes.iconBtn} onClick={handleNameSearchClick}>
                                            <SearchIcon />
                                        </IconButton>
                                    </Tooltip>
                                </InputAdornment>
                            ),
                            className: classes.endAdornment
                        }}
                    />
                </Grid>
                <Grid item xs={12} lg={6} className={classes.searchField}>
                    <TextField
                        className={classes.searchTextField}
                        label="Search Incident Description"
                        variant="outlined"
                        size="small"
                        value={filterText || ""}
                        onChange={handleSearchChange}
                        helperText='Please enter at least 4 character to search on'
                        error={showFilteredTextError()}
                        FormHelperTextProps={{ hidden: !showFilteredTextError() }}
                        onKeyDown={handleKeyDown}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {filterText && (
                                        <Tooltip title="Clear" placement="bottom-end" classes={{ tooltip: classes.tooltip }}>
                                            <IconButton className={classes.iconBtn}>
                                                <ClearIcon className={`clearIcon ${classes.clearIcon}`}
                                                           onClick={handleFilteredTextClear}
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    <Tooltip title="Search Description" placement="bottom-end" classes={{ tooltip: classes.tooltip }}>
                                        <IconButton className={classes.iconBtn} onClick={handleSearchClick}>
                                            <SearchIcon />
                                        </IconButton>
                                    </Tooltip>
                                </InputAdornment>
                            ),
                            className: classes.endAdornment
                        }}
                    />
                </Grid>
            </Grid>
            <TableContainer>
                <Table aria-label="Incidents">
                    <EnhancedTableHead
                        headCells={headCells}
                        sortDirection={incidentsTable.orderBy}
                        sortColumn={incidentsTable.sortBy}
                        onRequestSort={handleSortClick}
                    />
                    <TableBody>{tableContent()}</TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={incidents.data ? incidents.data.totalCount : 0}
                rowsPerPage={incidentsTable.rowsPerPage}
                page={incidentsTable.page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
            />
            <CSVLink
                className={classes.csvLink}
                ref={csvExportLinkRef}
                headers={csvHeaders}
                data={exportData}
                filename={`incidents${formatShortDate(Date())}.csv`}
            />

            <DeleteIncidentDialog
                isOpen={isDeleteOpen}
                setIsOpen={setIsDeleteOpen}
                isDri={isDri}
                archiveIncidentId={archiveIncidentId}
                page={incidentsTable.page}
                rows={incidentsTable.rowsPerPage}
                siteExternalIds={siteExternalIds}
            />

            <CSVLink
                className={classes.csvLink}
                ref={csvExportLinkRef}
                headers={csvHeaders}
                data={exportData}
                filename={`incidents${formatShortDate(Date())}.csv`}
            />
        </CardBase>
    );
}

export default IncidentsTableCard;
